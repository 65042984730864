import React from 'react';
import { FaBookOpen, FaGlobe, FaCogs, FaMicrochip, FaBrain, FaCalculator, FaLeaf } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function AimAndScope() {
    return (
        <div className="px-4 py-8 mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold mb-6 text-gray-100 animate-pulse">Aim and Scope</h1>
            <p className="text-lg text-gray-300 mb-6 ">
                IJNGTSR:<br/> International Journal of Next Generation Technology and Science Research is open to submission from scholars and experts in the wide areas of Advance Technology & Science Research.
            </p>
            <p className="text-lg text-gray-300 mb-6 ">
                The scope of the journal includes all issues in the field of Next Generation Technology & Science. The IJNGTSR is not restricted to the topics mentioned. The IJNGTSR publishes in conformity with publication ethics codes based on the COPE (Committee on Publication Ethics: <a href="https://publicationethics.org/" className="text-blue-400 hover:underline">https://publicationethics.org/</a>). Additionally, the IJNGTSR publication complies strictly with the general research ethics codes of the IJNGTSR (<a href="https://www.ijngtsr.org" className="text-blue-400 hover:underline">https://www.ijngtsr.org</a>).
            </p>
            <p className="text-lg text-gray-300 mb-6 ">
            International Journal of Next Generation Technology and Science Research (IJNGTSR) papers are solicited from, but not limited to, the following topics:
            </p>
            <ul className="list-disc pl-5 mb-6 text-gray-300">
                <li className="flex items-center mb-2">
                    <FaBookOpen className="text-blue-400 mr-2" />
                    Science and Knowledge in General
                </li>
                <li className="flex items-center mb-2">
                    <FaGlobe className="text-blue-400 mr-2" />
                    Physics
                </li>
                <li className="flex items-center mb-2">
                    <FaLeaf className="text-blue-400 mr-2" />
                    Chemistry
                </li>
                <li className="flex items-center mb-2">
                    <FaMicrochip className="text-blue-400 mr-2" />
                    Biology
                </li>
                <li className="flex items-center mb-2">
                    <FaCogs className="text-blue-400 mr-2" />
                    Earth Sciences
                </li>
                <li className="flex items-center mb-2">
                    <FaGlobe className="text-blue-400 mr-2" />
                    Space Sciences
                </li>
                <li className="flex items-center mb-2">
                    <FaBrain className="text-blue-400 mr-2" />
                    Computer Science
                </li>
                <li className="flex items-center mb-2">
                    <FaMicrochip className="text-blue-400 mr-2" />
                    Artificial Intelligence
                </li>
                <li className="flex items-center mb-2">
                    <FaCalculator className="text-blue-400 mr-2" />
                    Machine Learning
                </li>
                <li className="flex items-center mb-2">
                    <FaLeaf className="text-blue-400 mr-2" />
                    Mathematics
                </li>
                <li className="flex items-center mb-2">
                    <FaBookOpen className="text-blue-400 mr-2" />
                    Agricultural Science
                </li>
            </ul>
            <p className="text-lg text-gray-300 mb-6 ">
                Reviews and tutorial articles on contemporary subjects are strongly encouraged. All papers are to be reviewed by at least three independent reviewers, and authors of all accepted papers would be required to complete a copyright form transferring all rights to the IJNGTSR. For more details, you can contact us <Link to="/contact" className="text-blue-400 hover:underline">here</Link>.
            </p>
        </div>
    );
}

export default AimAndScope;
