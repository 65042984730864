import React from 'react';
import { Link } from 'react-router-dom';

const CallForEditorialBoardMembers = () => {
  return (
    <div className="p-6 bg-gray-700 rounded-lg shadow-md">
      <h1 className="text-[20px] sm:text-2xl font-bold mb-4 animate-pulse">Call for Reviewers/Editorial Members</h1>
      <p className="text-lg text-gray-300 mb-4">
        As a platform for global academic communication, the quality of our journal has always been an aspect attracting much of our attention. To ensure the quality of our publication and to better serve peers in the academic circle, we now call for Editorial Board members among professionals and experts worldwide. Professionals and experts who meet the following requirements are encouraged to join us, and together, we will work hard to become a world-class academic journal.
      </p>
      <h2 className="text-xl font-semibold mb-2 animate-bounce">Basic Requirements</h2>
      <ul className="list-disc list-inside mb-4 text-lg text-gray-300">
        <li>The applicant must hold a PhD (doctoral) degree, or be a Professor of an accredited academic institution.</li>
        <li>The applicant must have good experience in his/her specific research field.</li>
        <li>The research field of the applicant must conform to the scope of our journal.</li>
      </ul>
      <p className="text-lg text-gray-300 mb-4">
        The responsibility of reviewers is to evaluate the quality, relevance, and merit of submitted papers. Comments should be as complete and detailed as possible and contain clear opinions about strengths, weaknesses, relevance, and importance to the field. Reviewers should consider themselves as mentors to the author(s), and comments should be specific and constructive, with suggestions intended to enhance the manuscript. Reviewers will be asked to confirm the levels of evidence classified by the authors and reflected in the manuscript's contents. Reviewers may be requested to review manuscripts up to four times and may be requested to author a commentary for publication related to the manuscript they reviewed. Reviewers who consistently exhibit excellent reviews and respond promptly to editorial requests are considered for invitation to the Editorial Board.
      </p>
      <h2 className="text-xl font-semibold mb-2 animate-bounce">Professional Requirements</h2>
      <ul className="list-disc list-inside mb-4 text-lg text-gray-300">
        <li>Possess a very strong command of the English language, including verbal and written fluency.</li>
        <li>Provide a current electronic resume or curriculum vitae.</li>
        <li>Provide areas of expertise/specialty with a listing of classifications and sub-classifications for each classification. An optional submission of the applicant's publications and presentations for the past 10 years relevant to their indicated areas of expertise/specialty will greatly increase the likelihood of being selected as a reviewer.</li>
        <li>Provide a brief summary outlining previous peer review/editing experience and why they are interested in reviewing for IJNGTSR journal. Such an initiative of interest, in addition to excellent and frequent reviewing, is taken into consideration when the Editor-in-Chief extends invitations to join the Editorial Board.</li>
      </ul>
      <h2 className="text-xl font-semibold mb-2 animate-bounce">Editorial Requirements</h2>
      <ul className="list-disc list-inside mb-4 text-lg text-gray-300">
        <li>Agree to return manuscripts in a timely manner.</li>
        <li>Agree not to distribute the manuscript or disclose information within the manuscript.</li>
        <li>Agree to review the first revision of a manuscript for which they provided the initial review.</li>
        <li>Agree to be released as a reviewer after two refusals to review, two failures to review, or three late reviews.</li>
        <li>Agree to be rated as a reviewer; this will reflect future selection to review.</li>
      </ul>
      <p className="text-lg text-gray-300 mb-4">
        If you are interested in becoming our reviewers/Editorial Board members, please send your C.V. to <a href="mailto:submit@ijngtsr.org" className="text-blue-500">submit@ijngtsr.org</a> or <Link to="/member-form" className='text-blue-500'>Member Form</Link>
      </p>
    </div>
  );
};

export default CallForEditorialBoardMembers;
