import React from 'react';
import { useNavigate } from 'react-router-dom';

const Head = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/online-paper-submission');
    };

    return (
        <div>
            <header className="bg-blue-900 text-white p-10 text-center">
                <div className='p-2'>
                    <p className="text-[17px] sm:text-2xl font-semibold">International Journal of Next Generation Technology and Science Research</p>
                    <p className="text-[18px] sm:text-2xl font-semibold mt-2">(IJNGTSR)</p>
                </div>
                <div className='p-2 mt-1'>
                    <button
                        onClick={handleButtonClick}
                        className="px-4 py-2 rounded-lg bg-blue-600 text-white border border-transparent hover:bg-blue-800 hover:text-white hover:border-blue-600 transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                        Submit Paper
                    </button>
                </div>
            </header>
        </div>
    );
};

export default Head;
