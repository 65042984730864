import React from 'react';

const MessageByEditor = () => {
  return (
    <div className="p-6 bg-gray-700 rounded-lg transform hover:scale-105 transition-transform duration-300">
      <h1 className="text-2xl font-bold mb-4 animate-bounce">Editor's Message</h1>
      <p className="text-lg text-gray-300 mb-4">
        Valuable people of the science world,
      </p>
      <p className="text-lg text-gray-300 mb-4">
        IJNGTSR: International Journal of Next Generation Technology and Science Research is an international, academic journal which is an interdisciplinary science platform where everybody associated with writing and notion can say a word. The first volume published is the first step of this work. I owe a debt of gratitude to the administration team and foreign representatives of our journal who have put in great effort during the foundation process, and to our valuable academicians on the editorial board.
      </p>
      <p className="text-lg text-gray-300 mb-4">
        Furthermore, I thank our worthy writers for supporting us with their articles in our first volume and our academicians for spending their time on the referee process of these articles. On this journey, which we embarked upon with the aim of making at least a tiny contribution to the academic world, having the support of esteemed individuals from the science community as authors and referees, and receiving your reviews and advice, will make us stronger.
      </p>
      <p className="text-lg text-gray-300 mb-4">
        In this sense, we at the International Journal of Next Generation Technology and Science Research will be thankful and appreciative of any kind of support.
      </p>
      <p className="text-lg text-gray-300 mb-4">
        - Dinesh Kumar
      </p>
    </div>
  );
};

export default MessageByEditor;
