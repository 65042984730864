import React from 'react';
import { FaUserEdit, FaMapMarkerAlt, FaEnvelope, FaGlobe, FaUniversity } from 'react-icons/fa';
import ProfIsraa from '../img/Prof.Israa.png';
import DrRAMACHANDRACG from '../img/Dr. RAMACHANDRA C. G . ..jpg';
import DrKarunaNidhi from '../img/Dr. Karuna Nidhi Pandagre.png';
import DrTaraFTaher from '../img/Dr. Tara F. Taher.png';

function EditorialCommittee() {
  return (
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h1 className="text-[20px] sm:text-3xl font-bold mb-6 text-gray-100 text-center animate-pulse">Editorial Committee IJNGTSR</h1>

      <div className="mb-12">
        <h2 className="text-[19px] sm:text-2xl md:text-3xl font-semibold text-gray-200 mb-4 flex items-center">
          <FaUserEdit className="text-gray-100 mr-2 text-xl md:text-2xl" />
          Editor-In-Chief
        </h2>
        <div className='max-w-full md:max-w-4xl mx-auto p-4 bg-gray-700 rounded-lg shadow-md text-gray-300 transform hover:scale-105 transition-transform duration-300 ease-in-out'>
        <p className="text-lg md:text-xl text-gray-300 mb-4">Dinesh Kumar</p>
        <div className="flex flex-row items-start md:items-center text-gray-300 mb-2 space-x-2">
          <FaMapMarkerAlt className="text-gray-100 text-lg md:text-xl" />
          <span className="text-sm md:text-base">
            03, Old Kota Fatak<br />
            Near Ram Mandir, Nagda (456335),<br />
            Madhya Pradesh, India
          </span>
        </div>
        <div className="flex items-center text-gray-300 whitespace-nowrap">
          <FaEnvelope className="text-gray-100 mr-2 text-base md:text-xl" />
          <span className="text-sm md:text-base">
            Email:
            <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline"> Send a Request</a>
          </span>
        </div>
        </div>
      </div>

      <div>
        <h2 className="text-[19px] sm:text-2xl md:text-3xl font-semibold text-gray-200 mb-6 flex items-center">
          <FaUniversity className="text-gray-100 mr-2 text-xl md:text-2xl" />
          Editorial Board Member
        </h2>


        {/* first Card */}
        <div className="max-w-full md:max-w-4xl mx-auto p-4 bg-gray-700 rounded-lg shadow-md text-gray-300">
          <div className="flex flex-col md:flex-row items-center md:items-start md:justify-normal mb-2">
            <img
              src={DrRAMACHANDRACG}
              alt="Dr. RAMACHANDRA C. G"
              className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mb-4 md:mb-0 md:mt-3"
            />
            <div className="text-center md:text-left md:ml-6">
              <h1 className="text-lg md:text-2xl lg:text-3xl text-white font-bold mb-2">Dr. RAMACHANDRA C. G</h1>
              <p className="text-sm md:text-base text-white">Associate Professor</p>
              <div className="mt-3 space-y-2 md:space-y-1">
                <div className="flex items-center text-gray-300 whitespace-nowrap">
                  <FaEnvelope className="text-gray-100 mr-2 text-base md:text-xl" />
                  <span className="text-sm md:text-base">
                    Email:
                    <a href="mailto:ramachandra.cg@gmail.com" className="text-blue-400 hover:underline">  Send a Request</a>
                  </span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaGlobe className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Profile: <a href="https://www.webofscience.com/wos/author/record/Z-1176-2019" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">View profile</a></span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Bengaluru, Karnataka, India</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center text-gray-300">
            <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl medias" />
            <span className='ml-1'>Presidency University, Bengaluru, Karnataka, India</span>
          </div> */}

          <div className="mb-4 mt-2">
            <p className="text-sm md:text-base">
              Academic professional with 24 years of experience in academia, research, and development. Proficient in team building and student/staff motivation with multiple international and national recognitions. Holds a Guinness World Record for the “Thickest Book in the World” with 1,00,100 pages. Authored 21 books, published 119 papers, and received 8 patents.
            </p>
          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Key Research</h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-1 text-sm md:text-base list-disc list-inside">
              <li>Mechanical Engineering</li>
              <li>Academic Administration</li>
              <li>Planning & Development</li>
              <li>Motivation & Leadership</li>
              <li>Research & Development</li>
              <li>Performance Evaluation</li>
              <li>Effective Teaching Methodology</li>
              <li>Students Academic Parenting</li>
              <li>Institute Development</li>
              <li>Course Design</li>
              <li>Project Management</li>
              <li>Faculty Development Programs</li>
              <li>Workshop Organization</li>
              <li>Industrial Collaboration</li>
              <li>Innovation Integration</li>
              <li>Patent Filing</li>
              <li>Academic Mentorship</li>
              <li>Quality Assurance</li>
              <li>Accreditation Process</li>
              <li>Technical Writing</li>
            </ul>

          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1 mt-3">Work History</h2>
            <ul className="text-sm md:text-base space-y-2 list-disc pl-5">
              <li><strong>Presidency University, Bengaluru</strong> - Professor of Mechanical Engineering (July 2018 - Present).</li>
              <li><strong>Srinivas Institute of Technology, Mangaluru</strong> - Professor & Head of Mechanical Engineering (July 2007 - July 2018).</li>
              <li><strong>Coorg Institute of Technology, Ponnampet</strong> - Lecturer & Head, Mechanical Engineering (Sept 2005 - July 2007).</li>
              <li><strong>GSSS Institute of Engineering & Technology for Women, Mysore</strong> - Lecturer, Mechanical Engineering (Oct 2003 - Aug 2005).</li>
            </ul>
          </div>
        </div>

        {/* Second Card */}
        <div className="max-w-full md:max-w-4xl mx-auto p-4 bg-gray-700 rounded-lg shadow-md text-gray-300 mt-6">
          <div className="flex flex-col md:flex-row items-center md:items-start md:justify-normal mb-2">
            <img
              src={DrKarunaNidhi}
              alt="Dr. Karuna Nidhi Pandagre"
              className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mb-4 md:mb-0 md:mt-3"
            />
            <div className="text-center md:text-left md:ml-6">
              <h1 className="text-lg md:text-2xl lg:text-[28px] text-white font-bold mb-2">Dr. Karuna Nidhi Pandagre</h1>
              <p className="text-sm md:text-base text-white">Associate Professor</p>
              <div className="mt-3 space-y-2 md:space-y-1">
                <div className="flex items-center text-gray-300 whitespace-nowrap">
                  <FaEnvelope className="text-gray-100 mr-2 text-base md:text-xl" />
                  <span className="text-sm md:text-base">
                    Email:
                    <a href="mailto:drkarunapandagre@bistbpl.in" className="text-blue-400 hover:underline">  Send a Request</a>
                  </span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaGlobe className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Profile: <a href="https://orcid.org/0000-0001-9122-7275" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">View profile</a></span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Bhopal, (M.P.),  India</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center text-gray-300">
            <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl medias" />
            <span className='ml-1'>Bansal Institute of Science and Technology, Bhopal (M.P.), India</span>
          </div> */}

          <div className="mb-4 mt-2">
            <p className="text-sm md:text-base">
              Motivating and talented Adjunct Professor/Education Specialist with <b>14 + years of experience</b> driven to inspire students to pursue academic and personal excellence. Strives to create a challenging and engaging learning environment in which students become life-long scholars and learners. Experience in Employee Relations, Counseling & correct guideline to students.
            </p>
          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Skills</h2>
            <ul className="text-sm md:text-base space-y-1 list-disc pl-4">
              <li>Positive Approach with good Mindset</li>
              <li>Curriculum Development</li>
              <li>Faculty Development</li>
              <li>Classroom Scheduling</li>
              <li>Senior Graduation Advising and Mentoring</li>
              <li>Collaborations both Intra/inter institutional Campus Initiatives and Ethos</li>
            </ul>

          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Key Research</h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 text-sm md:text-base list-disc list-inside">
              <li>Data Science</li>
              <li>Cloud Computing</li>
              <li>Distributed System</li>
              <li>Machine Learning</li>
              <li>Computer Network</li>
              <li>Soft. Engineering</li>
              <li>Operating System</li>
              <li>DBMS with RDBMS</li>
              <li>Oracle</li>
              <li>Dot net Technology</li>
            </ul>

          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Project Handling ,</h2>
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Program Coordinator</h2>
            <ul className="text-sm md:text-base space-y-1 list-disc pl-4">
              <li>Appointed as a NPTEL SPOC.</li>
              <li>IPR –IPR Cell Coordinator.</li>
              <li>TG/NBA work also done at Department level.</li>
              <li>Appointed as a Superintendent/Assistant Superintendent in Barkatullah University, Bhopal Examination Control Room.</li>
              <li>Member of Organizing committee in National Conference on “Innovative and Emerging Trends in Information Technology”, on 27-28 September, 2013 at Career College, Bhopal.</li>
              <li>Indeed committee member in Various Activities in Bhopal.</li>
              <li>Representative of various activities in Institutional Social Responsibility (ISR).</li>
            </ul>

          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1 mt-3">Academic Experience</h2>
            <ul className="text-sm md:text-base space-y-1 list-disc pl-5">
              <li><strong>Associate Professor in Department of MCA</strong> at Bansal Institute of Science and Technology, Bhopal<br /> from October 2021 to till Date.</li>
              <li><strong>Assistant Professor in Department of Computer Science</strong> at Career College, Bhopal <br />from July 2011 to October 2021.</li>
              <li><strong>Empanelled as an Academic Counselor</strong> at IGNOU study center.</li>
            </ul>
          </div>

          <div className="mb-1">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1 mt-3">Technical Experience</h2>
            <ul className="text-sm md:text-base space-y-1 list-disc pl-5">
              <li><strong>Technical Analyst in Department of Computer Applications</strong><br />at University Institute of Technology, Bhopal (MP)<br />from 2007 to July 2011.</li>
            </ul>
          </div>
        </div>




        {/* Third card */}
        <div className="max-w-full md:max-w-4xl mx-auto p-4 bg-gray-700 rounded-lg shadow-md text-gray-300 mt-6">
          <div className="flex flex-col md:flex-row items-center md:items-start md:justify-normal mb-2">
            <img
              src={ProfIsraa}
              alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)"
              className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mb-4 md:mb-0 md:mt-4"
            />
            <div className="text-center md:text-left md:ml-6">
              <h1 className="text-lg md:text-2xl lg:text-3xl text-white font-bold mb-2">Prof. Israa Abdurrahman</h1>
              <p className="text-sm md:text-base text-white">Tikrit University/ College of Education for Women</p>
              <div className="mt-3 space-y-2 md:space-y-1">
                <div className="flex items-center text-gray-300 whitespace-nowrap">
                  <FaEnvelope className="text-gray-100 mr-2 text-base md:text-xl" />
                  <span className="text-sm md:text-base">
                    Email:
                    <a href="mailto:israaburhan@tu.edu.iq" className="text-blue-400 hover:underline">  Send a Request</a>
                  </span>
                </div>


                <div className="flex items-center text-gray-300">
                  <FaGlobe className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Profile: <a href="https://orcid.org/0000-0001-6063-5540" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">View profile</a></span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Location: Saladdin, Iraq</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Research Experience</h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 text-sm md:text-base list-disc list-inside">
              <li>Discourse Analysis</li>
              <li>Pragmatics</li>
              <li>Psycholinguistics</li>
              <li>Stylistics</li>
              <li>Semantics</li>
              <li>Ecolinguistics</li>
              <li>General Linguistics</li>
              <li className='text-[15px]'>Cognitive Linguistics</li>
              <li className='text-[13px]'>Phonetics & Phonology</li>
            </ul>
          </div>

          <div className="mb-4">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Academic Experience</h2>
            <ul className="text-sm md:text-base space-y-2 list-disc pl-5">
              <li><strong>Material Taught in BA Courses:</strong> Phonetics, Phonology, Syntax, Linguistics, Listening and Speaking, Composition, Comprehension, Essay Writing.</li>
              <li><strong>Material Taught in MA Courses:</strong> Contrastive Analysis, Phonetics and Phonology, Psycholinguistics, Applied Linguistics, Discourse Analysis, Assessment, General Linguistics.</li>
              <li><strong>Material Taught in PhD Courses:</strong> Psycholinguistics, Applied Linguistics, Discourse Analysis, Cognitive Linguistics.</li>
            </ul>
          </div>
        </div>



        
        {/* Forth card */}
        <div className="max-w-full md:max-w-4xl mx-auto p-4 bg-gray-700 rounded-lg shadow-md text-gray-300 mt-6">
          <div className="flex flex-col md:flex-row items-center md:items-start md:justify-normal mb-2">
            <img
              src={DrTaraFTaher}
              alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)"
              className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mb-4 md:mb-0 md:mt-4"
            />
            <div className="text-center md:text-left md:ml-6">
              <h1 className="text-lg md:text-2xl lg:text-3xl text-white font-bold mb-2">Dr. Tara F. Taher</h1>
              <p className="text-sm md:text-base text-white">Koya University - Department of Chemistry</p>
              <div className="mt-3 space-y-2 md:space-y-1">
                <div className="flex items-center text-gray-300 whitespace-nowrap">
                  <FaEnvelope className="text-gray-100 mr-2 text-base md:text-xl" />
                  <span className="text-sm md:text-base">
                    Email:
                    <a href="mailto:tara.fuad@koyauniversity.org" className="text-blue-400 hover:underline">  Send a Request</a>
                  </span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaGlobe className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Profile: <a href="https://scholar.google.com/citations?user=nee-GzIAAAAJ&hl=en" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">View profile</a></span>
                </div>
                <div className="flex items-center text-gray-300">
                  <FaMapMarkerAlt className="text-gray-100 mr-2 text-lg md:text-xl" />
                  <span>Location: Iraq</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Research Experience</h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 text-sm md:text-base list-disc list-inside">
            <li>Sensors</li>
              <li>Electrochemistry</li>
              <li>Analytical methods</li>
              <li className='min-w-80'>Environmental analysis</li>
            </ul>
          </div>
          <div className="mb-4">
            <h2 className="text-xl md:text-1xl lg:text-1xl text-white font-semibold mb-1">Academic Experience</h2>
            <ul className="text-sm md:text-base space-y-2 list-disc pl-5">
              <li><strong>Participation in the Staff Development Training </strong> for Medium Level Leaders of Kurdistan Universities - Iraq, in University of Kassel, Germany, ARO – The Scientific Journal of Koya University</li>
              <li><strong>Lecturer in the Faculty of Science and Health :</strong> at Koya University- KRG of Iraq (2007 - Current)</li>
              <li><strong>Head of Chemistry Department :</strong> Faculty of Science and Health / Koya University (2013 - 2017)</li>
              <li><strong>Vice Dean :</strong> Vice Dean at Faculty of Science and Health and the same time head of chemistry department (2014 - 2015)</li>
              <li><strong>Executive Editor :</strong> ARO – The Scientific Journal of Koya University (2014 - Current)</li>
              <li><strong>Participation </strong> in the Staff Development Training for Medium Level Leaders of Kurdistan Universities - Iraq, in University of Kassel, Germany (2013)</li>
            </ul>
          </div>
        </div>
           {/* ........... */}





      </div>

    </div>


  );
}

export default EditorialCommittee;
