import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlusCircle, FaEdit, FaFileAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Dashb = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem('authToken');
      navigate('/admin');
    }, 2000);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-800 text-gray-100 p-4 mt-4">
      <div className="w-full flex justify-between items-center mb-6">
        <h1 className="text-[28px] sm:text-4xl font-bold">Dashboard</h1>
        <button
          onClick={handleLogout}
          className="relative bg-red-600 text-white font-bold py-1 px-2 mt-0 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
          disabled={loading}
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <>
              Logout
            </>
          )}
        </button>
      </div>
      <div className='mt-11'>
      &nbsp;
      </div>
      <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-center lg:gap-8 lg:mt-8">
    <NavLink to={'/add-article'}>
        <div className="flex flex-col items-center p-6 bg-gray-700 rounded-lg shadow-lg w-full mb-10 lg:mb-0 hover:bg-gray-600 transform hover:scale-105 transition-transform duration-300">
            <FaPlusCircle className="text-6xl text-indigo-400 mb-4" />
            <h2 className="text-2xl font-bold mb-2 animate-pulse">Add Article</h2>
            <p className="text-center">Click to Add New Status.</p>
        </div>
    </NavLink>
    <NavLink to={'/update-article'}>
        <div className="flex flex-col items-center p-6 bg-gray-700 rounded-lg shadow-lg w-full lg:w-12/12 mb-10 lg:mb-0 hover:bg-gray-600 transform hover:scale-105 transition-transform duration-300">
            <FaEdit className="text-6xl text-indigo-400 mb-4" />
            <h2 className="text-2xl font-bold mb-2 animate-pulse">Update Article</h2>
            <p className="text-center">Click to Update Status.</p>
        </div>
    </NavLink>
    <NavLink to={'/show-paper'}>
        <div className="flex flex-col items-center p-6 bg-gray-700 rounded-lg shadow-lg w-full lg:w-12/12 mb-10 lg:mt-0 hover:bg-gray-600 transform hover:scale-105 transition-transform duration-300">
            <FaFileAlt className="text-6xl text-indigo-400 mb-4" />
            <h2 className="text-2xl font-bold mb-2 animate-pulse">Show Paper</h2>
            <p className="text-center">Click to Show Save Paper.</p>
        </div>
    </NavLink>
    <NavLink to={'/show-member'}>
        <div className="flex flex-col items-center p-6 bg-gray-700 rounded-lg shadow-lg w-full lg:w-12/12 mb-10 lg:mt-0 hover:bg-gray-600 transform hover:scale-105 transition-transform duration-300">
            <FaFileAlt className="text-6xl text-indigo-400 mb-4" />
            <h2 className="text-2xl font-bold mb-2 animate-pulse">Show Member</h2>
            <p className="text-center">Click to Show M. Detail. </p>
        </div>
    </NavLink>
</div>
    </div>
  );
};

export default Dashb;
