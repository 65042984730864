import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <div className="mt-5">
      <footer className="bg-blue-900 text-white py-6">
    <div className="">
      <div className="flex flex-wrap justify-between">
        <div className="w-full container mx-auto px-4">
          <h4 className="text-lg font-semibold mb-2">Privacy Statement</h4>
          <p className="text-white">
          The names and email addresses entered in this journal site will be used exclusively for the stated purposes of this Publication and will not be made available for any other purpose or to any other party.
          </p>
        </div>
      </div>
      <NavLink to="/">
      <div className="mt-2 text-center text-white">
        <p>&copy; {new Date().getFullYear()} IJNGTSR All Rights Reserved</p>
      </div>
      </NavLink>
    </div>
  </footer>
    </div>
  )
}

export default Footer
