import React from 'react';
import { FaDollarSign, FaCheckCircle, FaMoneyBillAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function PublicationCharges() {
  return (
    <div>
    <Helmet>
      <meta charset="utf-8" />
      <title>IJNGTSR - Publication Charges</title>
      <meta name="description" content="Publication Charges. International Journal of Advanced ... International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
      <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
    </Helmet>
    <div className="max-w-4xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-gray-100 mb-4 animate-pulse">Publication Charges</h1>
      <p className="text-lg text-gray-300 mb-4 ">
      IJNGTSR: International Journal of Next Generation Technology and Science Research (IJNGTSR) is an initiative to provide a quality publishing platform for research scholars. All the papers are published after successful peer review by qualified researchers.
      </p>
      <p className="text-lg text-gray-300 mb-4 ">
        All articles published in our journal are open access and freely available online, immediately upon publication. This is made possible by an article-processing charge (APC) that covers the range of publishing services we provide. This includes provision of online tools for editors and authors, article production and hosting, liaison with abstracting and indexing services, and customer services. The APC, payable when your manuscript is editorially accepted and before publication, is charged to either you, your funder, institution, or employer.
      </p>
      <div className="bg-gray-700 p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-lg text-gray-300 mb-4 animate-bounce">Publishing Charges for Online Publication</h2>
        {/* <p className="text-red-500 font-semibold mb-4">DUE TO COVID-19, WE ARE PUBLISHING WITH 50% DISCOUNT.</p> */}
        <p className="text-gray-100 mb-4">
          This charge is applicable up to 30 pages; above 30 pages, authors will be charged $5 USD per page.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-600 p-4 rounded-lg shadow-sm transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-lg font-semibold text-gray-100 mb-2">Middle/Low-income Economies</h3>
            <p className="text-gray-100 mb-2">Publication fee: 70 USD (up to 30 pages)</p>
            <p className="text-gray-100 mb-4">E Publication Certificate: Free of Cost</p>
            <FaDollarSign className="text-green-500 text-2xl mb-2" />
          </div>
          <div className="bg-gray-600 p-4 rounded-lg shadow-sm transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-lg font-semibold text-gray-100 mb-2">High-income Economies</h3>
            <p className="text-gray-100 mb-2">Publication fee: 80 USD (up to 30 pages)</p>
            <p className="text-gray-100 mb-4">E Certificate: Free of Cost</p>
            <FaDollarSign className="text-green-500 text-2xl mb-2" />
          </div>
        </div>
      </div>
      <div className="bg-gray-700 p-6 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-xl font-semibold text-gray-100 mb-4 animate-pulse">Mode of Payment</h2>
        <p className="text-lg text-gray-300 mb-4 ">
          Pay your publication charges through PayPal. Processing Fee has to be paid only if your article gets accepted for publishing & indexing. Processing Fee could be paid by means of Demand Draft, Net Banking, Wire Transfer, or Direct Account Deposit. Account details will be sent to you with the acceptance letter.
        </p>
        <FaMoneyBillAlt className="text-blue-500 text-2xl mb-2" />
      </div>
      <div className="bg-gray-700 p-6 rounded-lg shadow-md mt-6 transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-xl font-semibold text-gray-100 mb-4 animate-pulse">Publication Process</h2>
        <ol className="list-decimal list-inside space-y-2 text-lg text-gray-300 mb-4">
          <li>After receiving the manuscript, we will send a submission acknowledgment email to the author.</li>
          <li>Manuscripts will be checked by plagiarism checker software.</li>
          <li>Primary quality will be checked by the editor.</li>
          <li>The manuscript will be sent for double-blinded review.</li>
          <li>Based on the double-blinded review, the editor will decide whether to publish. Decisions can be accepted without revision, resubmit with major/minor revisions, or rejected.</li>
          <li>After the final review, if the paper is accepted, we will send a payment request to the author.</li>
          <li>The author needs to deposit the publication fee after the payment request.</li>
        </ol>
        <FaCheckCircle className="text-green-500 text-2xl mt-4" />
      </div>
    </div>
    </div>
  );
}

export default PublicationCharges;
