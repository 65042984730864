import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding, faEnvelope, faBriefcase, faGraduationCap, faFlask } from '@fortawesome/free-solid-svg-icons';
import { FaSpinner } from 'react-icons/fa';

const EditorialBoardForm = () => {
  const [formData, setFormData] = useState({
    salutation: '',
    fullName: '',
    designation: '',
    affiliation: '',
    researchExperience: '',
    academicExperience: '',
    email: '',
    country: '',
    agree: false,
  });

  const API_KEY = '154122054'; 

  const [loading, setLoading] = useState(false); 
  const [successMessage, setSuccessMessage] = useState(''); 

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
  
    try {
      const response = await fetch('https://y7v8shlb4c.execute-api.ap-south-1.amazonaws.com/default/EditorialBoardMembers-lambda', {
        method: 'POST',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        console.log('Error details:', errorDetails);
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      setSuccessMessage(result.message);
      setTimeout(() => {
        setSuccessMessage('');  
      }, 8000);   
      handleReset();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    setFormData({
      salutation: '',
      fullName: '',
      designation: '',
      affiliation: '',
      researchExperience: '',
      academicExperience: '',
      email: '',
      country: '',
      agree: false,
    });
  };

  return (
    <div className="min-h-screen bg-gray-800 flex items-center justify-center p-5">
      <div className="bg-gray-700 text-white max-w-4xl mx-auto px-4 py-6 rounded-lg transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-[17px] sm:text-2xl font-bold text-center mb-5 text-white animate-pulse">
          Join as Editorial Board Member (IJNGTSR)
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
    
          {/* Salutation */}
          <div>
            <label htmlFor="salutation" className="block text-sm font-medium text-gray-100 mb-1">
              <strong>Salutation :</strong>
            </label>
            <div className="relative">
              <select
                id="salutation"
                name="salutation"
                value={formData.salutation}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">- Select a value -</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Dr.">Dr.</option>
                <option value="Prof.">Prof.</option>
                <option value="Master">Master</option>
                <option value="Sr.">Sr.</option>
              </select>
            </div>
          </div>
    
          {/* Full Name */}
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Full Name :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faUser} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your full name"
                required
              />
            </div>
          </div>
    
          {/* Designation */}
          <div>
            <label htmlFor="designation" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Designation :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faBriefcase} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your designation"
                required
              />
            </div>
          </div>
    
          {/* Affiliation */}
          <div>
            <label htmlFor="affiliation" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Affiliation :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faBuilding} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="affiliation"
                name="affiliation"
                value={formData.affiliation}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your affiliation"
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Country :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faBuilding} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Country"
                required
              />
            </div>
          </div>
    
          {/* Research Experience */}
          <div>
            <label htmlFor="researchExperience" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Research Experience :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faFlask} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="researchExperience"
                name="researchExperience"
                value={formData.researchExperience}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your research experience"
                required
              />
            </div>
          </div>
    
          {/* Academic Experience */}
          <div>
            <label htmlFor="academicExperience" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Academic Experience :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faGraduationCap} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                id="academicExperience"
                name="academicExperience"
                value={formData.academicExperience}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your academic experience"
                required
              />
            </div>
          </div>
    
          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-100 mb-2">
              <strong>Email :</strong>
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faEnvelope} className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full pl-10 px-4 py-2 border border-gray-400 bg-gray-700 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your email"
                required
              />
            </div>
          </div>
    
          {/* Agreement */}
          <div>
            <label className="inline-flex items-center text-gray-100">
              <input
                type="checkbox"
                name="agree"
                checked={formData.agree}
                onChange={handleInputChange}
                className="form-checkbox h-4 w-4 text-blue-500"
                required
              />
              <span className="ml-2 text-sm">I agree to the terms and conditions</span>
            </label>
          </div>
          <div>
            <label className="flex items-center mb-0">
              <span className="text-sm font-semibold text-white mb-0">
                I confirm that I have read the roles and responsibilities of an Editorial Board member and understand that my selection is based on the decision of the current Editorial Board. I will fulfill my duties to the best of my ability for the benefit of IJNGTSR.
              </span>
            </label>
          </div>
    
          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading} // Disable button while loading
            >
              {loading ? <FaSpinner className="animate-spin mr-2" /> : 'Submit'}
            </button>
          </div>

            {/* Display success message */}
        {successMessage && (
          <div className="text-blue-500 text-lg p-4 rounded-lg mb-4 text-center">
            {successMessage}
          </div>
        )}
        </form>
      </div>
    </div>
  );
};

export default EditorialBoardForm;
