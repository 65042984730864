import React from 'react';
import { FaHandshake, FaLink, FaFileSignature, FaBullhorn, FaHeadset, FaBook } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function ConferenceSupport() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>IJNGTSR - Conference Support</title>
        <meta name="description" content="It is well known fact in the recent days bountiful conferences ... International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
        <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
      </Helmet>
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h1 className="text-[19px] sm:text-3xl font-bold mb-6 text-gray-100 animate-pulse">Conference Support</h1>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaHandshake className="text-blue-400 mr-2" />
          What We Offer
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR:<br/> International Journal of Next Generation Technology and Science Research (IJNGTSR) is presently looking for opportunities to cooperate with conference organizers across the globe to publish the conference proceedings separately or as an addition issue in IJNGTSR. It is a recent development and new addition to our service.
        </p>
        <p className="text-lg text-gray-300 mb-4">
          We offer end-to-end support to organizations, including:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-300 mb-4 space-y-2">
          <li className="flex items-center"><FaLink className="text-yellow-400 mr-2" />Providing a separate webpage for the conference on our site if needed.</li>
          <li className="flex items-center"><FaFileSignature className="text-green-400 mr-2" />Provision of an online submission form for conference articles.</li>
          <li className="flex items-center"><FaBullhorn className="text-red-400 mr-2" />Promotional support in online and offline forms.</li>
          <li className="flex items-center"><FaHeadset className="text-purple-400 mr-2" />Technical and academic support for the conference organizers.</li>
          <li className="flex items-center"><FaBook className="text-orange-400 mr-2" />Publication of conference proceedings through our journals or as a separate proceedings with ISBN/ISSN number.</li>
        </ul>
        <p className="text-lg text-gray-300 mb-4">
          All published articles will be submitted to databases where IJNGTSR articles are deposited.
        </p>
      </div>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaBook className="text-blue-400 mr-2" />
          Service Charges
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          We charge a service fee based on the services offered. Print mode incurs additional printing charges per copy, with options to publish with ISSN or ISBN numbers. Institutional memberships are also available to support our journal system.
        </p>
      </div>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-[19px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaBullhorn className="text-yellow-400 mr-2" />
          Approval Duration
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          Approval for conference support is typically decided within 10 days from receipt of consent. In some cases, it may take 15-20 days. Organizations are requested to apply well before the conference date.
        </p>
      </div>
    </div>
    </div>
  );
}

export default ConferenceSupport;
