import React from 'react';
import { FaArchive, FaCalendarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function PastIssues() {
  return (
    <div className="px-4 py-8 mx-auto max-w-6xl">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
        <FaArchive className="text-blue-500 mr-2" />
        Past Issues
      </h1>
      
      <div className="bg-gray-700 p-4 sm:p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4">
          <FaCalendarAlt className="inline text-yellow-400 mr-2" />
          Year 2024 Issues
        </h2>

        <Link to='/volume_1' className='block'>
          <ul className="list-disc pl-5 text-blue-400 mb-6">
            <li className="border-b-2 border-yellow-400 pb-2 inline-block">
              Volume 1- Issue 1- Sep.&nbsp;2024
            </li>
          </ul>
        </Link>
      </div>
    </div>
  );
}

export default PastIssues;
