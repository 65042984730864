import React, { useEffect } from 'react';

const ChatBox = () => {

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};

    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/66b68b220cca4f8a7a7407e6/1i4sgv0c4';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    script.async = true;
    document.body.appendChild(script);

    const style = document.createElement('style');
    style.textContent = `
      /* Hide default Tawk.to chat icon */
      .tawk-custom-color {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, []);


  return (
    <div>
      <style>
  {`
    .chat-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      z-index: 1000;
    }
  `}
</style>

    </div>
  );
};

export default ChatBox;
