import React from 'react';

const ProgressBar = ({ currentStep }) => {
  const progressWidth = `${(currentStep + 1) * 25}%`;

  const steps = ['Submit', 'Accept', 'Review', 'Publish',];

  return (
    <div className="w-full px-4 py-4 media-query">
  <div className="relative grid w-full h-24 m-0 overflow-hidden text-white place-items-center">
    <div className="w-full px-4 md:px-10 pt-4 pb-8">
      <div className="relative flex items-center justify-between w-full">
        <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-white/50"></div>
        <div
          className="absolute left-0 top-2/4 h-0.5 bg-white transition-all duration-500"
          style={{ width: progressWidth }}
        ></div>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`relative z-10 grid h-4 w-4 place-items-center rounded-full font-bold text-white transition-all duration-300 ${
              currentStep >= index ? '!bg-white' : '!bg-blue-gray-50'
            }`}
          >
            <div className="absolute -bottom-8 sm:-bottom-10 md:-bottom-[2.3rem] w-max text-center text-xs sm:text-sm md:text-base">
              <h6 className="block font-sans antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                {step}
              </h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

  );
};

export default ProgressBar;
