import React from 'react';
import { FaPaperPlane, FaFileAlt, FaListUl, FaImage, FaEdit, FaQuoteLeft, FaFileSignature, FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function InstructionsForAuthors() {
  return (
    <div className="px-4 py-8 mx-auto max-w-6xl">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
        <FaFileAlt className="text-green-500 mr-2" />
        Instructions For Authors
      </h1>
      
      <section className="bg-gray-700 p-6 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaPaperPlane className="text-blue-400 mr-2" />
          How to Submit Manuscripts
        </h2>
        <p className="text-lg text-gray-300 mb-4">
          Please indicate your understanding that the article will not be under consideration by any other publisher while it is being reviewed by IJNGTSR: International Journal of Next Generation Technology and Science Research. Please also alert us if a similar or related article has been accepted or is under consideration by another journal. In such cases, we may request a copy of the similar or related article. All submissions to IJNGTSR must be submitted electronically via the <Link to="/online-paper-submission" className="text-blue-400 hover:underline">IJNGTSR online management system</Link>.
        </p>
        <p className="text-lg text-gray-300 mb-4">
          Authors can also send their papers to <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline">submit@ijngtsr.org</a>.
        </p>
      </section>

      <section className="bg-gray-700 p-6 rounded-lg shadow-lg mb-8 transform hover:scale-105 hover:mt-10 transition-transform duration-300 ease-in-out">
        <h2 className="text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaFileAlt className="text-yellow-400 mr-2" />
          Components of a Manuscript
        </h2>
        
        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaListUl className="text-green-400 mr-2" />
            Title Page
          </h3>
          <ul className="list-disc pl-5 text-gray-300">
            <li>Title of no more than 20 words</li>
            <li>Full names and affiliations of all authors</li>
            <li>Email address for the corresponding author</li>
          </ul>
        </div>
        
        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaQuoteLeft className="text-red-400 mr-2" />
            Abstract
          </h3>
          <p className="text-gray-300">
            The abstract should be a condensed version of the final presentation and include all significant findings. It should not exceed 250 words.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaPen className="text-purple-400 mr-2" />
            Keywords
          </h3>
          <p className="text-gray-300">Include 3 to 8 keywords or short phrases for indexing.</p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaEdit className="text-teal-400 mr-2" />
            Body Text
          </h3>
          <p className="text-gray-300">
            The text should be divided into sections, each with a separate heading and numbered consecutively.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaImage className="text-orange-400 mr-2" />
            Tables and Figures
          </h3>
          <p className="text-gray-300">Must be submitted together with the main text manuscript.</p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaFileSignature className="text-cyan-400 mr-2" />
            Results
          </h3>
          <p className="text-gray-300">Should be clear and concise.</p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaEdit className="text-pink-400 mr-2" />
            Discussion
          </h3>
          <p className="text-gray-300">
            Should explore the significance of the results, not repeat them. Avoid extensive citations.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaQuoteLeft className="text-red-400 mr-2" />
            Conclusions
          </h3>
          <p className="text-gray-300">
            Main conclusions may be presented in a short Conclusions section.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaListUl className="text-gray-400 mr-2" />
            Acknowledgments
          </h3>
          <p className="text-gray-300">
            Topically relevant funding sources and conflicts of interest should be disclosed. Please omit the word "number" from grant or contract acknowledgments.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaFileAlt className="text-teal-300 mr-2" />
            References
          </h3>
          <p className="text-gray-300">
            References should be numbered sequentially as they appear in the text.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaFileAlt className="text-green-300 mr-2" />
            Copyright and Article Processing Fee
          </h3>
          <p className="text-gray-300">
            If your paper is accepted, the corresponding author will be required to complete a Copyright form and pay the Article Processing Fee. Details are provided on the publication charges page.
          </p>
        </div>

        <div className="mb-4 text-lg text-gray-300">
          <h3 className="text-xl font-semibold text-gray-200 mb-2 flex items-center">
            <FaPaperPlane className="text-blue-300 mr-2" />
            Publishing Process
          </h3>
          <p className="text-gray-300">
            Papers are published through the following steps: submission, screening, peer review, editorial decision, copy editing, proofreading, and publishing.
          </p>
        </div>
      </section>
    </div>
  );
}

export default InstructionsForAuthors;
