import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/nav';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import LeftNav from './components/Left-Nav'; 
import RightNav from './components/Right-Nav'; 
import Head from './components/Head';
import Footer from './components/Footer';
import ScrollingText from './components/ScrollingText';
import Track from './components/Track';
import AimAndScope from './components/AimAndScope';
import EditorialCommittee from './components/Editorial-community';
import LatestAnnouncement from './components/LatestAnnouncement';
import ConferenceSupport from './components/ConferenceSupport';
import CurrentIssue from './components/CurrentIssue';
import PastIssues from './components/Past-issue';
import InstructionsForAuthors from './components/InstructionsForAuthors';
import OnlinePaperSubmission from './components/OnlinePaperSubmission';
import PublicationCharges from './components/Publicationcharges';
import Indexingservices from './components/Indexing-services';
import OpenAccess from './components/open-access';
import FAQ from './components/Faq';
import CallForEditorialBoardMembers from './components/CallForEditorialBoardMembers';
import MessageByEditor from './components/MessageByEditor';
import ContactForHelp from './components/ContactForHelp';
import PublicationEthicStatement from './components/PublicationEthicStatement';
import ScrollToMain from './ScrollToTop';
import Admin from './components/Admin';
import Dashb from './components/Dashb';
import Addarticle from './components/Addarticle';
import Updatearticle from './components/Updatearticle';
import { Helmet } from 'react-helmet';
import ChatBox from './components/ChatBox'
import Showpaper from './components/Show-paper';
import EditotialBoardMemberForm from './components/EditorialBoardMemberform';
import Showmember from './components/Show-member';
import Payfees from './components/PaymentPage';
import Volumeone from './components/Volume/Volume_1_';

function App() {
  const mainRef = useRef(null);

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>International Journal of Next Generation Technology &amp; Science Research - IJNGTSR</title>
        <meta name="description" content="International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
        <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
      </Helmet>
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <Head />
        <ScrollingText />
        <div className="relative flex flex-1 flex-col md:flex-row px-4">
          <LeftNav className="rounded-lg md:basis-1/4 md:w-1/4 w-full mb-4 md:mb-0" />
          <main ref={mainRef} className="p-4 bg-gray-800 text-white rounded-lg shadow-lg mb-4 md:mb-0 md:mx-2 lg:mx-2 flex-1">
            <ScrollToMain mainRef={mainRef} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/track-article" element={<Track />} />
              <Route path="/aim-and-scope" element={<AimAndScope />} />
              <Route path="/editorial-community" element={<EditorialCommittee/>} />
              <Route path="/latest-announcement" element={<LatestAnnouncement/>} />
              <Route path="/conference-support" element={<ConferenceSupport/>} />
              <Route path="/current-issue" element={<CurrentIssue/>} />
              <Route path="/past-issue" element={<PastIssues/>} />
              <Route path="/instructions-for-authors" element={<InstructionsForAuthors/>} />
              <Route path="/online-paper-submission" element={<OnlinePaperSubmission/>} />
              <Route path="/publication-charge" element={<PublicationCharges/>} />
              <Route path="/indexing-services" element={<Indexingservices/>} />
              <Route path="/open-access" element={<OpenAccess/>} />
              <Route path="/faq" element={<FAQ/>} />
              <Route path="/call-for-editor-member" element={<CallForEditorialBoardMembers/>} />
              <Route path="/message-by-editor" element={<MessageByEditor/>} />
              <Route path="/contact-for-help" element={<ContactForHelp/>} />
              <Route path="/publication-authic-statement" element={<PublicationEthicStatement/>} />
              <Route path="/admin" element={<Admin/>} />
              <Route path="/dashb" element={<Dashb/>} />
              <Route path="/add-article" element={<Addarticle/>} />
              <Route path="/update-article" element={<Updatearticle/>} />
              <Route path="/show-paper" element={<Showpaper/>} />
              <Route path="/member-form" element={<EditotialBoardMemberForm/>} />
              <Route path="/show-member" element={<Showmember/>} />
              <Route path="/pay-fees" element={<Payfees/>} />
              <Route path="/volume_1" element={<Volumeone/>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <RightNav className="rounded-lg md:basis-1/4 md:w-1/4 w-full" />
        </div>
        <ChatBox />
        <Footer />
      </div>
    </Router>
    </div>
  );
}

export default App;
