import React from 'react';
import { FaHome, FaList, FaCalendar, FaUsers, FaInfoCircle, FaFileAlt, FaDollarSign, FaUpload } from 'react-icons/fa';
import { GiBrain, GiBookCover, GiArchiveResearch } from 'react-icons/gi';
import { MdAssignment, MdVisibility } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import Arrow from "../img/aarow.png";
import Manuscript from "../tem/manuscript-template.docx";

const Left = ({ className }) => (
  <aside className={`w-64 bg-gray-800 text-white ${className}`}>
    <nav className="p-4">
      <ul className="space-y-3">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">IJNGTSR</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaHome className='ml-1'/>
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/track-article"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaList className='ml-1'/>
            <span>Track Article</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/aim-and-scope"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <GiBrain className='ml-1'/>
            <span>Aim and Scope</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/editorial-community"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaUsers className='ml-1'/>
            <span>Editorial Community</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/latest-announcement"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaCalendar className='ml-1'/>
            <span>Latest Announcement</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/conference-support"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <GiBookCover className='ml-1'/>
            <span>Conference Support</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaInfoCircle className='ml-1'/>
            <span>About Us</span>
          </NavLink>
        </li>
      </ul>

      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Article Inside</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>

        <ul className="mt-4 space-y-3">
          <li>
            <NavLink
              to="/current-issue"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <MdVisibility className='ml-1'/>
              <span>Current Issue</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/past-issue"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <GiArchiveResearch className='ml-1'/>
              <span>Past Issue</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Authors</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <ul className="mt-4 space-y-3">
          <li>
            <NavLink
              to="/instructions-for-authors"
              className={({ isActive }) =>
                `flex items-center text-lg space-x-2 rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <MdAssignment className='ml-1'/>
              <span>Instructions for Authors</span>
            </NavLink>
          </li>
          <li>
            <a
              href={Manuscript}
              className="flex items-center text-lg space-x-2 rounded-md hover:text-gray-400"
              download
            >
              <FaFileAlt className='ml-1'/>
              <span>Manuscript Template</span>
            </a>
          </li>
          <li>
            <NavLink
              to="/publication-charge"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaDollarSign className='ml-1'/>
              <span>Publication Charge</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="https://drive.google.com/file/d/1SDihhhQmot8m9wPRF4IayOQfvGlTwZ9E/view?pli=1"
              target='__blank'
              className="flex items-center text-lg space-x-2 rounded-md hover:text-gray-400"
            >
              <FaFileAlt className='ml-1'/>
              <span>Copyright Form</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/online-paper-submission"
              className={({ isActive }) =>
                `flex items-center text-lg space-x-2 rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaUpload className='ml-1'/>
              <span>Online Paper Submission</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Others</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <ul className="mt-4 space-y-3">
          <li>
            <NavLink
              to="/indexing-services"
              className={({ isActive }) =>
                `flex items-center text-lg space-x-2 rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <GiBookCover className='ml-1' />
              <span>Indexing Services</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/open-access"
              className={({ isActive }) =>
                `flex items-center text-lg space-x-2 rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <GiBookCover className='ml-1'/>
              <span>Open Access</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
);

export default Left;
