import React from 'react';
import { FaQuestionCircle, FaFileAlt, FaMoneyBillWave, FaPenFancy, FaPaperPlane, FaRegUser } from 'react-icons/fa';

function FAQ() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-700 rounded-lg shadow-lg">
      <h1 className="text-2xl sm:text-[17px] font-bold text-gray-100 mb-6 flex items-center space-x-2">
        <FaQuestionCircle className="text-blue-500" />
        <span>Frequently Asked Questions</span>
      </h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaPenFancy className="text-green-500" />
          <span>What do I need to know to prepare and submit my article?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          In accordance with academic and professional protocols, IJNGTSR cannot accept an article if it is not the author's original work, has been published before (but see below regarding conference papers), or is currently under consideration for publication elsewhere. The article must not contain any libelous or unlawful statements or in any way infringe the rights of others. All authors must declare they have read and agreed to the content of the submitted article. The author must be the owner of the copyright and be entitled to sign the Author Agreement. In submitting an article, the author complies with these conditions. In addition, the publisher reserves the right to re-referee and/or reject an accepted paper if the paper does not meet the criteria outlined in the review form or if the paper is in some other way deemed possibly unsuitable. If the Author wishes to abandon the review process or remove his/her article from elsewhere in the system before publication, the article must be formally withdrawn in writing before it can be submitted to another journal. Non-compliance with any of the above conditions may result in sanctions.
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaFileAlt className="text-yellow-500" />
          <span>What if my article has been published elsewhere?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          The submission cannot have been previously published in English (but see below regarding conference papers), nor can it be before another journal for consideration. If the submission is based on a conference paper, the conference paper requirements in Author Guidelines - Article Preparation must be met. Previous publication in another language should also be disclosed. Such statements, along with explanations for exceptions to the above rules, should be included in the 'Comments to the Editor' section (for online submission), or in the covering letter (for email submission).
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaMoneyBillWave className="text-green-500" />
          <span>Are there any fees involved and what about Open Access (OA)?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          There is a registration fee for publishing with IJNGTSR to cover the publishing expenses. The articles accepted for publication are available online and freely accessible to all without any restriction except use for commercial purposes.
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaPenFancy className="text-purple-500" />
          <span>What do I need to know when writing an article (word count, format, templates)?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          The text must meet the formatting requirements outlined at Author Guidelines - Article Preparation. This page also gives details of additional files to be included with email submissions. To find out whether the journal you are submitting to prefers submissions via our online system or via email, see the item on submission below.
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaPaperPlane className="text-blue-500" />
          <span>How do I submit my article?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          Articles are submitted either by email or through the Online Submissions and Peer-Review System (OSPEERS), depending on the journal. To find out which system you should use, look in the Notes for Authors tab on your chosen journal's homepage.
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaQuestionCircle className="text-indigo-500" />
          <span>What happens to my article when I have submitted it?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          All journals published by IJNGTSR place great emphasis on the quality of the articles they publish. Therefore, submitted articles are put through a full double-blind refereeing process in which:
          <ul className="list-disc ml-6">
            <li>Articles are sent to 2-3 experts for their peer review.</li>
            <li>The reviewers' recommendations determine whether an article will be: Accepted, Accepted subject to minor changes, Accepted subject to resubmission with significant changes, Rejected.</li>
            <li>For articles which require changes, the same reviewers will be used to ensure that the quality of the revised article is acceptable.</li>
          </ul>
        </p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100 mb-2 flex items-center space-x-2">
          <FaRegUser className="text-red-500" />
          <span>What if my article has multiple authors?</span>
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          We would expect a "corresponding author" to be responsible for the article during the refereeing process after submission, and for the collection and provision of the copyright assignment from each author after acceptance.
        </p>
      </div>
    </div>
  );
}

export default FAQ;
