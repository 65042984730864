import React, { useState, useRef } from 'react';
import { FaFileUpload, FaEnvelope, FaFlag, FaCheckCircle, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function OnlinePaperSubmission() {
  const [formData, setFormData] = useState({
    authorName: '',
    email: '',
    country: '',
    file: '',
    fileName: '',
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            file: reader.result.split(',')[1], 
            fileName: file.name,
          }));
        };
        reader.readAsDataURL(file);
        setError('');
      } else {
        setError('Only Word (.doc, .docx) files are allowed.');
      }
    }
  };

  const validateForm = () => {
    const { authorName, email, country, file, fileName } = formData;
    if (!authorName || !email || !country || !file || !fileName) {
      setError('All fields are required.');
      return false;
    }
    setError('');
    return true;
  };

  const API_KEY = '154122054'; 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    setLoading(true);
  
    try {
      const response = await fetch('https://a8hmedpav9.execute-api.ap-south-1.amazonaws.com/default/SubmitPaperFunction', {
        method: 'POST',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
      
      if (response.ok) {
        setSuccessMessage('Paper submitted successfully!');
        setFormData({
          authorName: '',
          email: '',
          country: '',
          file: '',
          fileName: '',
        });
        fileInputRef.current.value = ''; 
        setTimeout(() => setSuccessMessage(''), 5000); 
      } else {
        console.error('Response error:', result); 
        setError(result.error || 'Failed to submit paper.');
      }
    } catch (error) {
      console.error('Fetch error:', error); 
      setError('Failed to submit paper.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>IJNGTSR - Online Paper Submission</title>
        <meta name="description" content="International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
        <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
      </Helmet>
      <div className="px-4 py-8 mx-auto max-w-6xl">
        <h1 className="text-[20px] sm:text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
          <FaFileUpload className="text-blue-500 mr-2" />
          Online Paper Submission
        </h1>


        <section className="bg-gray-700 p-6 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300">
          {/* <p className="text-gray-300 mb-4">
            <FaExclamationCircle className="inline text-red-500 mr-2" />
            DUE TO COVID-19, WE ARE PUBLISHING WITH 50% DISCOUNT.
          </p> */}
          <p className="text-lg text-gray-300 mb-4 ">
            IJNGTSR:<br/> International Journal of Next Generation Technology and Science Research (IJNGTSR) welcomes authors to submit papers concerning all fields of technology, science, and their applications in business, industry, and other subjects. The journal covers a broad range of innovative research (Scope).
          </p>
          <h2 className="text-[19px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-pulse">
            <FaCheckCircle className="text-green-400 mr-2" />
            Online Paper Submission for Current Issue <br></br> (September 2024)
          </h2>
          <p className="text-lg text-gray-300 mb-4 ">
            Note: You will receive a submission acknowledgment in your email within 8 hours after submission. Please check your Inbox / Spam box.
          </p>
          
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-100 mb-2 flex items-center">
                <FaEnvelope className="text-yellow-400 mr-2" />
                Author Name:
              </label>
              <input
                type="text"
                name="authorName"
                value={formData.authorName}
                onChange={handleInputChange}
                className="w-full p-2 rounded bg-gray-100 text-black"
                required
              />
            </div>

            <div>
              <label className="block text-gray-200 mb-2 flex items-center">
                <FaEnvelope className="text-yellow-400 mr-2" />
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 rounded bg-gray-100 text-black"
                required
              />
            </div>

            <div>
              <label className="block text-gray-200 mb-2 flex items-center">
                <FaFlag className="text-purple-400 mr-2" />
                Country:
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full p-2 rounded bg-gray-100 text-black"
                required
              />
            </div>

            <div>
              <label className="block text-gray-200 mb-2 flex items-center">
                <FaFileUpload className="text-blue-400 mr-2" />
                Select Word or Doc File:
              </label>
              <input
                type="file"
                name="file"
                accept=".doc,.docx,.pdf"
                onChange={handleFileChange}
                ref={fileInputRef} // Attach the ref to the file input
                className="w-full p-2 rounded bg-gray-700 text-gray-300"
                required
              />
              {error && <p className="text-red-300 mt-2">{error}</p>}
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white p-2 rounded flex items-center justify-center"
            >
              {loading ? (
                <FaSpinner className="animate-spin mr-2" />
              ) : (
                'Submit Paper'
              )}
            </button>

            {successMessage && <strong><p className="text-green-400 mt-4 text-center">{successMessage}</p></strong>}
          </form>
        </section>

        <section className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-[17px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
            <FaExclamationCircle className="text-blue-400 mr-2" />
            Other Way of Submission
          </h2>
          <p className="text-lg text-gray-300 mb-4 ">
            When submitting papers for potential publication in IJNGTSR, please submit an original editable file in one of the (.doc, .pdf) formats.
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
          All figures, images, tables, etc., should be embedded into the original file.
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
          Further information on the Author Guidelines is available upon enquiry.
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
          Authors must accept the terms of the Honor Code and Plagiarism Statement for Paper Submission. 
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
          Manuscripts should report original, previously unpublished research results, experimental or theoretical.
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
          Articles must not be under consideration for publication elsewhere.
          </p>
          <p className="text-lg text-gray-300 mb-4 ">
            Prospective authors are invited to submit full-text papers including results, tables, figures, and references via email to <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline">submit@ijngtsr.org</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default OnlinePaperSubmission;
