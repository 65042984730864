import React, { useState } from 'react';
import { FaSync } from 'react-icons/fa'; 

const Refresh = ({ onRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onRefresh();
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); 
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`p-2 rounded-full transition-transform duration-300 ease-in-out ${
        isLoading ? 'bg-gray-800 text-white' : 'bg-gray-800 text-gray-300'
      } hover:bg-gray-800 hover:text-white`}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <FaSync
        className={`text-2xl ${isLoading ? 'animate-spin' : ''}`}
      />
    </button>
  );
};

export default Refresh;
