import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const AddArticle = () => {
  const [number, setNumber] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const API_KEY = '154122054'; 
  

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!number.match(/^\d{8}$/)) {
      setError('Please enter an 8-digit number.');
      return;
    }
    if (!status) {
      setError('Please select a status.');
      return;
    }
    setError('');
    setLoading(true);

    try {
      const response = await fetch('https://qcthvmao2f.execute-api.ap-south-1.amazonaws.com/articles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
        },
        body: JSON.stringify({ articleNumber: number, status }),
      });

      if (response.ok) {
        setSuccessMessage('Article Created Successfully');
        setNumber('');
        setStatus('');
        setError('');

        setTimeout(() => setSuccessMessage(''), 2000);
      } else {
        const result = await response.json();
        if (result === 'This article already exists.') {
          setError(result);
          setTimeout(() => setError(''), 2000);
        } else {
          setError('Failed to send data.');
        }
      }
    } catch (error) {
      setError('An error occurred while sending data.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem('authToken');
      navigate('/admin');
    }, 2000);
  };

  const handleReset = () => {
    setNumber('');
    setStatus('');
    setError('');
  };

  return (

    <>
      <NavLink to={'/dashb'}>
        <div className="flex items-center space-x-2 p-2 bg-gray-800 rounded-lg transition duration-300 mb-4">
          <FaArrowLeft className="text-2xl text-white" />
          <h4 className="text-lg font-semibold text-white">Back</h4>
        </div>
      </NavLink>
      <div className="min-h-screen flex flex-col items-center bg-gray-800 text-gray-100 p-4 mt-4">
        <div className="w-full flex justify-between items-center mb-6">
          <h1 className="text-[25px] sm:text-4xl font-bold">Add New Article</h1>
          <button
            onClick={handleLogout}
            className="relative bg-red-600 text-white font-bold py-1 px-2 mt-0 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <>
                Logout
              </>
            )}
          </button>
        </div>
        <form onSubmit={handleAdd} className="w-full max-w-lg bg-gray-700 p-6 rounded-lg shadow-lg">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {successMessage && <p className="text-green-500 mb-4 text-center">{successMessage}</p>}
          <div className="mb-4">
            <label className="block text-indigo-100 text-sm font-bold mb-2" htmlFor="number">
              8-Digit Number:
            </label>
            <input
              id="number"
              type="text"
              pattern="\d{8}"
              className="w-full py-2 px-3 rounded-md bg-gray-800 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-300"
              placeholder="Enter 8-digit Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-indigo-100 text-sm font-bold mb-2" htmlFor="status">
              Status:
            </label>
            <select
              id="status"
              className="w-full py-2 px-3 rounded-md bg-gray-800 text-gray-400 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <option value="" disabled>Select Article Status</option>
              <option value="Submit">Submit</option>
              <option value="Accept">Accept</option>
              <option value="Review">Under Review</option>
              <option value="Publish">Publish</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-300"
              disabled={loading}
            >
              Add
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="bg-red-500 text-white font-bold py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
              disabled={loading}
            >
              Reset
            </button>
          </div>
        </form>
        <div>
          <NavLink to={'/update-article'}>
            <h1 className='text-center mt-4 text-blue-300'><strong>Click To Show Article</strong></h1>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default AddArticle;
