import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import RefreshButton from './Refresh';

const Updatearticle = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);
  const [deletingArticle, setDeletingArticle] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false); 
  const navigate = useNavigate();
  
  const API_KEY = '154122054'; 

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/admin');
    } else {
      fetchArticles();
    }
  }, [navigate]);

  const fetchArticles = async () => {
    try {
      const response = await fetch('https://qcthvmao2f.execute-api.ap-south-1.amazonaws.com/articles', {
        headers: {
          'x-api-key': API_KEY,
        },
      });
      const data = await response.json();
      setArticles(data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem('authToken');
      navigate('/admin');
    }, 2000);
  };

  const handleEdit = (article) => {
    setEditingArticle(article);
    setIsEditing(true);
  };

  const handleUpdate = async () => {
    try {
      await fetch(`https://qcthvmao2f.execute-api.ap-south-1.amazonaws.com/articles/${editingArticle.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(editingArticle),
      });
      setSuccessMessage('Article updated successfully');
      setShowSuccess(true); 
      setIsEditing(false);
      fetchArticles();
      setTimeout(() => {
        setShowSuccess(false); 
      }, 3000);
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await fetch(`https://qcthvmao2f.execute-api.ap-south-1.amazonaws.com/articles/${deletingArticle.id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': API_KEY,
        },
      });
      setSuccessMessage('Article deleted successfully');
      setShowSuccess(true); 
      setShowDeleteConfirm(false);
      fetchArticles();
      setTimeout(() => {
        setShowSuccess(false); 
      }, 3000);
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  return (
    <>
      <NavLink to={'/dashb'}>
        <div className="flex items-center space-x-2 p-2 bg-gray-800 rounded-lg transition duration-300">
          <FaArrowLeft className="text-2xl text-white" />
          <h4 className="text-lg font-semibold text-white">Back</h4>
        </div>
      </NavLink>
      <div className="min-h-screen flex flex-col items-center bg-gray-800 text-gray-100 p-4 mt-4">
        <div className="w-full flex justify-between items-center mb-6">
          <h1 className="text-[28px] sm:text-4xl font-bold">Update</h1>
          <RefreshButton onRefresh={fetchArticles} />
          <button
            onClick={handleLogout}
            className="relative bg-red-600 text-white font-bold py-1 px-2 mt-0 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              'Logout'
            )}
          </button>
        </div>

        {articles.length === 0 ? (
          <div className="p-4 rounded-lg text-center">
            <p className="text-red-600 text-lg font-semibold">No data available. Please add some data.</p>
          </div>
        ) : (
          <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden text-center">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-600">ID</th>
                <th className="py-2 px-4 border-b border-gray-600">Article Number</th>
                <th className="py-2 px-4 border-b border-gray-600">Status</th>
                <th className="py-2 px-4 border-b border-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, index) => (
                <tr key={article.id}>
                  <td className="py-2 px-4 border-b border-gray-600">{index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-600">{article.articleNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-600">{article.status}</td>
                  <td className="py-2 px-4 border-b border-gray-600 flex space-x-2 ml-3 lg:ml-8">
                    <button
                      onClick={() => handleEdit(article)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => {
                        setDeletingArticle(article);
                        setShowDeleteConfirm(true);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Edit Modal */}
        {isEditing && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg lg:w-1/3">
              <h2 className="text-xl font-bold mb-4">Edit Article</h2>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="number">
                  Article Number:
                </label>
                <input
                  id="number"
                  type="text"
                  disabled
                  value={editingArticle.articleNumber}
                  onChange={(e) => setEditingArticle({ ...editingArticle, articleNumber: e.target.value })}
                  className="w-full py-2 px-3 rounded-md bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="status">
                  Status:
                </label>
                <select
                  id="status"
                  value={editingArticle.status}
                  onChange={(e) => setEditingArticle({ ...editingArticle, status: e.target.value })}
                  className="w-full py-2 px-3 rounded-md bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                >
                  <option value="Submit">Submit</option>
                  <option value="Accept">Accept</option>
                  <option value="Review">Under Review</option>
                  <option value="Publish">Publish</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleUpdate}
                  className="bg-green-600 text-white font-bold py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-300"
                >
                  Update
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-600 text-white font-bold py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-gray-800 p-4 rounded-lg shadow-lg lg:w-1/3">
              <h2 className="text-xl font-bold mb-4">Delete Article</h2>
              <p className="mb-4">Are you sure you want to delete this article?</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleDelete}
                  className="bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
                >
                  Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="bg-gray-600 text-white font-bold py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {showSuccess && (
          <div className="mt-4 p-4 bg-green-500 text-white rounded-lg">
            {successMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default Updatearticle;
