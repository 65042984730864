import React from 'react';
import { FaBullhorn, FaPaperPlane, FaVirus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function LatestAnnouncement() {
  return (
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h1 className="text-[19px] sm:text-3xl font-bold mb-6 text-gray-100 animate-pulse">Latest Announcement</h1>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-[19px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaBullhorn className="text-yellow-400 mr-2" />
          Call for Papers
        </h2>
        <p className="text-lg text-gray-300 mb-4">
          Volume 1, Issue 1, September 2024 - You can submit your paper <Link to="/online-paper-submission" className="text-blue-400 hover:underline">here</Link>.
        </p>
      </div>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-[19px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaPaperPlane className="text-green-400 mr-2" />
          Reviewer Openings
        </h2>
        <p className="text-lg text-gray-300 mb-4">
          Latest opening for reviewers in our Editorial board. If you are eligible, please <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline">mail your CV</a> to submit@ijngtsr.org.
        </p>
      </div>

      <div className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-[19px] sm:text-2xl font-semibold text-gray-200 mb-4 flex items-center animate-bounce">
          <FaVirus className="text-red-400 mr-2" />
          Covid-19 Discount
        </h2>
        <p className="text-lg text-gray-300">
          Due to Covid-19, we are offering a 50% discount on publication fees.
        </p>
      </div>
    </div>
  );
}

export default LatestAnnouncement;
