import React from 'react';
import { FaBook, FaLock, FaOpenid, FaDollarSign } from 'react-icons/fa'; 
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div>
    <Helmet>
      <meta charset="utf-8" />
      <title>IJNGTSR - About us</title>
      <meta name="description" content="About IJNGTSR. The IJNGTSR is a worldwide open access publisher serving the academic research community by launching peer-reviewed journals covering a wide ... International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
      <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
    </Helmet>
    <div className="bg-gray-800 text-white max-w-4xl mx-auto px-4 py-6">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-2 animate-pulse">
          <FaBook className="inline-block mr-2" /> About IJNGTSR
        </h1>
      </header>

      <section className="bg-gray-700 p-4 mb-8 rounded-lg transform hover:scale-105 transition-transform duration-300 ease-in-out" >
        <h2 className="text-2xl font-semibold mb-4 animate-bounce">
          <FaBook className="inline-block mr-2" /> About IJNGTSR
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          The IJNGTSR is a worldwide open access publisher serving the academic research community by launching peer-reviewed journals covering a wide range of academic disciplines. As an international academic organization for researchers & scientists, we aim to provide researchers, writers, academic professors, and students the most advanced research achievements in a broad range of areas, and to facilitate academic exchange between them. Compliant with open access policies, published materials can be copied and distributed without obtaining permission as long as a correct citation to the original publication is given. We are committed to the advancement of science and the applications through our publications. At IJNGTSR Research Publishing, we want to ensure that your publishing experience goes as smoothly as possible so that you can focus on what really counts.
        </p>
      </section>

      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-4 animate-bounce">
          <FaOpenid className="inline-block mr-2" /> Open Access
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          Open access is the practice of providing unrestricted access to peer-reviewed academic journal articles via the internet. It is also increasingly being provided to scholarly monographs and book chapters. All original research papers published by IJNGTSR are available freely and permanently accessible online immediately after publication. Readers are free to copy and distribute the contribution under the creative commons attribution-non-commercial licence. Authors can benefit from the open access publication model in several ways:
        </p>
        <ul className="text-lg text-gray-300 mb-4 ">
          <li>High Availability and High Visibility - free and unlimited accessibility of the publication over the internet without any restrictions.</li>
          <li>Rigorous peer review of research papers - Fast, high-quality double-blind peer review.</li>
          <li>Faster publication with less cost - Papers published on the internet without any subscription charge.</li>
          <li>Higher Citation - open access publications are more frequently cited.</li>
        </ul>
      </section>

      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-4 animate-bounce">
          <FaLock className="inline-block mr-2" /> Publication Ethics Statement
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR is committed to maintaining the highest quality original research materials. All papers accepted for publication will be subjected to a rigorous peer-review. Any papers suspected of plagiarism, falsification, and inauthentic authorship will never be published.
        </p>
      </section>

      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-4 animate-bounce">
          <FaBook className="inline-block mr-2" /> Creative Commons Attribution License (CC-BY)
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          All articles published by IJNGTSR will be distributed under the terms and conditions of the Creative Commons Attribution License (CC-BY). So anyone is allowed to copy, distribute, and transmit the article on condition that the original article and source are correctly cited.
        </p>
      </section>

      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-4 animate-bounce">
          <FaDollarSign className="inline-block mr-2" /> Article Processing Charges
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          In an open access model, we promise that readers don't pay a subscription fee to access online published articles. For authors, only some fundamental costs like editing, production, and peer-review are included to maintain normal operations of our company. These Article Processing Charges (APC) are only used to support publishers to make the published articles freely available to all readers. In order to encourage long-term scientific research, authors are eligible for various discounts on Article Processing Charges (APC).
        </p>
      </section>
    </div>
    </div>
  );
}

export default About;
