import React from 'react';
import { FaEnvelope, FaRegNewspaper, FaBookOpen } from 'react-icons/fa'; 
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>International Journal of Next Generation Technology &amp; Science Research - IJNGTSR</title>
        <meta name="description" content="International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
        <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
      </Helmet>
    <div className="bg-gray-800 text-white max-w-4xl mx-auto px-4 py-6">
      <header className="text-center mb-6">
        <h1 className="text-[22px] sm:text-3xl font-bold mb-2 animate-pulse">
          <FaBookOpen className="inline-block mr-2" /> Welcome to IJNGTSR
        </h1>
        <p className="text-lg">
          Dear Colleagues,
        </p>
      </header>
      <section className="mb-6">
        <p className="text-lg text-gray-300 mb-4 ">
          The IJNGTSR: International Journal of Next Generation Technology and Science Research, welcomes you. IJNGTSR would like to thank you for your online journal interest. The online journal system has been diffused very fast since September, 2024. It has continued to diffuse new trends in Advanced Technology & Science to all over the world. We hope that the journal will also successfully accomplish our global science and technology goal.
        </p>
        <p className="text-lg text-gray-300 mb-4 ">
          Any views expressed in journal are the views of the authors and are not the views of the Editor and IJNGTSR.
        </p>
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR invites you article contributions. Submitted articles should be about all aspects of Advanced Technology & Science. The articles should be original, unpublished, and not in consideration for publication elsewhere at the time of submission to IJNGTSR. Manuscripts must be submitted in English.
        </p>
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR is guided by its editors, guest editors, and advisory boards. If you are interested in contributing to IJNGTSR as an author, guest editor, or reviewer, please send your CV to <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline flex items-center animate-bounce mt-2"><FaEnvelope className="mr-2" /> submit@ijngtsr.org</a>.
        </p>
        <p className="text-lg text-gray-300 mb-4 ">
          The publication of an article in a peer-reviewed journal is an essential model for our journal. It is necessary to agree upon standards of expected ethical behavior for all parties involved in the act of publishing: the author, the journal editor, the peer reviewer, and the publisher. Our ethic statements are based on Committee on Publication Ethics, COPE’s Best Practice Guidelines for Journal Editors. For more information visit: <a href="/publication-ethics" target='__blank' className="text-blue-400 hover:underline flex items-center animate-bounce mt-2"><FaRegNewspaper className="mr-2" /> Publication Ethic Statements</a>.
        </p>
        <p className="text-lg mb-4 text-gray-300 bg-gray-700 p-5 rounded-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
          Language: English.<br />
          Type: Peer-Review.<br />
          Frequency: Monthly.
        </p>
      </section>
      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-2 animate-pulse">
          <FaRegNewspaper className="inline-block mr-2" /> Article Invitation
        </h2>
        <p className="text-base mb-4">
          Paper Submission is Open for Upcoming Issue September 2024
        </p>
        <p className="text-lg text-gray-300 mb-4 ">
          We invite research/review articles from authors all over the year. IJNGTSR is accepting papers year-round for consideration for publication. IJNGTSR is published as a monthly journal. Articles submitted will be published once a month. The journal publishes peer-reviewed original research papers, case studies, review articles, and technical notes. The journal allows free access to its contents, which is likely to attract more readers and citations to articles published in IJNGTSR. The Journal will accept original and innovative submissions in English on the understanding that the work is unpublished and is not being considered for publication elsewhere.
        </p>
      </section>
      <section className="bg-gray-700 p-4 rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-2 animate-pulse">
          <FaBookOpen className="inline-block mr-2" /> Mission
        </h2>
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR is pleased to offer free access to online publishing. We are committed to promoting academic exchanges and progress. Publishing with IJNGTSR will provide high visibility of your research work and keep you informed about the latest academic trends. The aim of the International Journal of Next Generation Technology and Science Research (IJNGTSR) is to foster the growth of educational, scientific, and industrial research activities among researchers and to provide a medium for mutual communication between the world academia and the industry on the one hand, and the world scientific community on the other.
        </p>
      </section>
    </div>
    </div>
  );
}

export default Home;
