import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToMain = ({ mainRef, offset = 70 }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (mainRef.current) {
      const element = mainRef.current;
      const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementTop - offset,
        behavior: 'smooth'
      });
    }
  }, [pathname, mainRef, offset]);

  return null;
};

export default ScrollToMain;

