import React from 'react';

const ScrollingText = () => {
    
    const scrollAnimation = {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
    };

    const scrollText = {
        display: 'inline-block',
        paddingLeft: '40%',
        animation: 'scroll 20s linear infinite',
        whiteSpace: 'nowrap',
    };

    const keyframes = `
        @keyframes scroll {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    `;

    return (
        <div style={scrollAnimation} className='container mx-auto px-4 py-2'>
            <style>{keyframes}</style>
            <div style={scrollText}>
                <p className="font-semibold text-red-600">   
                    We are Publishing Free of Cost.
                </p>
            </div>
        </div>
    );
};

export default ScrollingText;
