import React from 'react';
import { FaQuestionCircle, FaUserTie, FaMoneyCheckAlt, FaPhoneAlt, FaChalkboardTeacher, FaPaperclip, FaFileAlt, FaUser } from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import Arrow from "../img/aarow.png";

const Right = ({ className }) => (
  <aside className={`w-64 bg-gray-800 text-white ${className}`} style={{ borderRadius: '9px' }}>
    <nav className="p-4">
      <div>
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">More</h2>
          <img className='arrow' src={Arrow} alt="Arrow indicating more content" />
          <div className="line-animation"></div>
        </div>
        <ul className="mt-4 space-y-3">
          <li>
            <NavLink
              to="/faq"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaQuestionCircle className='ml-1' />
              <span>FAQ</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/publication-authic-statement"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaFileAlt className='ml-1' />
              <span>Publication Authic Statement</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-for-help"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaPhoneAlt className='ml-1' />
              <span>Contact for Help</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/message-by-editor"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <MdMessage className='ml-1' />
              <span>Message by Editor</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/call-for-editor-member"
              className={({ isActive }) =>
                `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
              }
            >
              <FaChalkboardTeacher className='ml-1' />
              <span>Call for Editor Member</span>
            </NavLink>
            <li>
              <NavLink
                to="/member-form"
                style={{ marginTop: '8px' }}
                className={({ isActive }) =>
                  `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
                }
              >
                <FaUser className='ml-1' />
                <span>Member Form</span>
              </NavLink>
            </li>
          </li>
        </ul>
      </div>


      {/* Second Section */}
      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Publication Fees</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <div className="mt-4">
          <NavLink
            to="/pay-fees"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaMoneyCheckAlt className='ml-1' />
            <span>Pay Fees</span>
          </NavLink>
        </div>
      </div>



      {/* Third Section */}
      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Admin Area</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <div className="mt-4">
          <NavLink
            to="/admin"
            className={({ isActive }) =>
              `flex items-center space-x-2 text-lg rounded-md ${isActive ? 'bg-gray-700' : 'hover:text-gray-400'}`
            }
          >
            <FaUserTie className='ml-1' />
            <span>Admin Login</span>
          </NavLink>
        </div>
      </div>

      {/* forth Section */}
      <div className="mt-8">
        <div className="relative group">
          <h2 className="text-xl font-semibold inline-block">Call For Paper</h2>
          <img className='arrow' src={Arrow} alt="International Journal of Next Generation Technology and Science Research (IJNGTSR)" />
          <div className="line-animation"></div>
        </div>
        <div className="mt-4">
          <p className="text-lg text-justify">
            The IJNGTSR: International Journal of Next Generation Technology and Science Research Inviting Papers/Articles for Current Issue Volume 1 Issue 1 September 2024.
            Submit your Paper through Online Submission System. Authors also can Send Paper to <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline">submit@ijngtsr.org</a>.
          </p>
          <div className="mt-4 flex items-center space-x-2">
            <NavLink
              style={{ textDecoration: 'none', color: 'white' }}
              to="/online-paper-submission"
              className={({ isActive }) =>
                `text-blue-400 hover:underline ${isActive ? 'bg-gray-700 rounded-md w-full p-1' : ''}`
              }
            >
              <FaPaperclip style={{ display: 'inline' }} className='ml-1' />
              &nbsp;&nbsp;Submit Paper
            </NavLink>
          </div>
        </div>
      </div>






    </nav>
  </aside>
);

export default Right;
