import React from 'react';
import { FaCheckCircle, FaLock, FaInfoCircle, FaBookOpen, FaBalanceScale, FaUserAlt } from 'react-icons/fa';

const PublicationEthicStatement = () => {
  return (
    <div className="p-6 bg-gray-700 rounded-lg shadow-md max-w-4xl mx-auto">
      <h1 className="text-[21px] sm:text-3xl font-bold mb-6 text-center animate-pulse">Publication Ethic Statement</h1>
      <p className="mb-6 text-lg leading-relaxed">
        The publication of an article in a peer-reviewed journal is an essential model for our journal. It is necessary to agree upon standards of expected ethical behavior for all parties involved in the act of publishing: the author, the journal editor, the peer reviewer, and the publisher. Our ethic statements are based on the Committee on Publication Ethics, COPE’s Best Practice Guidelines for Journal Editors.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaCheckCircle className="mr-2 text-green-600"/>Publication Decisions</h2>
      <p className="mb-6 text-lg leading-relaxed">
        The editor is responsible for deciding which of the articles submitted to the journal should be published. The editor may be guided by the policies of the journal's editorial board and constrained by such legal requirements as shall then be in force regarding libel, copyright infringement, and plagiarism. The editor may confer with other editors or reviewers in making this decision.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaBalanceScale className="mr-2 text-blue-600"/>Fair Play</h2>
      <p className="mb-6 text-lg leading-relaxed">
        An editor at any time evaluates manuscripts for their intellectual content without regard to race, gender, sexual orientation, religious belief, ethnic origin, citizenship, or political philosophy of the authors.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaLock className="mr-2 text-purple-600"/>Confidentiality</h2>
      <p className="mb-6 text-lg leading-relaxed">
        The editor and any editorial staff must not disclose any information about a submitted manuscript to anyone other than the corresponding author, reviewers, potential reviewers, other editorial advisers, and the publisher, as appropriate.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaInfoCircle className="mr-2 text-red-600"/>Disclosure and Conflicts of Interest</h2>
      <p className="mb-6 text-lg leading-relaxed">
        Unpublished materials disclosed in a submitted manuscript must not be used in an editor's own research without the express written consent of the author.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaBookOpen className="mr-2 text-orange-600"/>Duties of Reviewers</h2>

      <h3 className="text-xl font-semibold mb-2">Contribution to Editorial Decisions</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Peer review assists the editor in making editorial decisions and through the editorial communications with the author may also assist the author in improving the paper.
      </p>

      <h3 className="text-xl font-semibold mb-2">Promptness</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Any selected referee who feels unqualified to review the research reported in a manuscript or knows that its prompt review will be impossible should notify the editor and excuse themselves from the review process.
      </p>

      <h3 className="text-xl font-semibold mb-2">Acknowledgement of Sources</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Reviewers should identify relevant published work that has not been cited by the authors. Any statement that an observation, derivation, or argument has been previously reported should be accompanied by the relevant citation. A reviewer should also call to the editor's attention any substantial similarity or overlap between the manuscript under consideration and any other published paper of which they have personal knowledge.
      </p>

      <h3 className="text-xl font-semibold mb-2">Standards of Objectivity</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Reviews should be conducted objectively. Personal criticism of the author is inappropriate. Referees should express their views clearly with supporting arguments.
      </p>

      <h3 className="text-xl font-semibold mb-2">Disclosure and Conflict of Interest</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Privileged information or ideas obtained through peer review must be kept confidential and not used for personal advantage. Reviewers should not consider manuscripts in which they have conflicts of interest resulting from competitive, collaborative, or other relationships or connections with any of the authors, companies, or institutions connected to the papers.
      </p>

      <h2 className="text-2xl font-semibold mb-4 flex items-center"><FaUserAlt className="mr-2 text-yellow-600"/>Duties of Authors</h2>

      <h3 className="text-xl font-semibold mb-2">Reporting Standards</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Authors of reports of original research should present an accurate account of the work performed as well as an objective discussion of its significance. Underlying data should be represented accurately in the paper. A paper should contain sufficient detail and references to permit others to replicate the work. Fraudulent or knowingly inaccurate statements constitute unethical behavior and are unacceptable.
      </p>

      <h3 className="text-xl font-semibold mb-2">Data Access and Retention</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Authors are asked to provide the raw data in connection with a paper for editorial review and should be prepared to provide public access to such data (consistent with the ALPSP-STM Statement on Data and Databases), if practicable, and should in any event be prepared to retain such data for a reasonable time after publication.
      </p>

      <h3 className="text-xl font-semibold mb-2">Originality and Plagiarism</h3>
      <p className="mb-6 text-lg leading-relaxed">
        The authors should ensure that they have written entirely original works, and if the authors have used the work and/or words of others, that this has been appropriately cited or quoted.
      </p>

      <h3 className="text-xl font-semibold mb-2">Multiple, Redundant or Concurrent Publication</h3>
      <p className="mb-6 text-lg leading-relaxed">
        An author should not in general publish manuscripts describing essentially the same research in more than one journal or primary publication. Submitting the same manuscript to more than one journal concurrently constitutes unethical publishing behavior and is unacceptable.
      </p>

      <h3 className="text-xl font-semibold mb-2">Acknowledgement of Sources</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Proper acknowledgment of the work of others must always be given. Authors should cite publications that have been influential in determining the nature of the reported work.
      </p>

      <h3 className="text-xl font-semibold mb-2">Authorship of the Paper</h3>
      <p className="mb-6 text-lg leading-relaxed">
        Authorship should be limited to those who have made a significant contribution to the conception, design, execution, or interpretation of the reported study. All those who have made significant contributions should be listed as co-authors. Where there are others who have participated in certain substantive aspects of the research project, they should be acknowledged or listed as contributors. The corresponding author should ensure that all appropriate co-authors and no inappropriate co-authors are included on the paper, and that all co-authors have seen and approved the final version of the paper and have agreed to its submission for publication.
      </p>

      <h3 className="text-xl font-semibold mb-2">Hazards and Human or Animal Subjects</h3>
      <p className="mb-6 text-lg leading-relaxed">
        If the work involves chemicals, procedures, or equipment that have any unusual hazards inherent in their use, the author must clearly identify these in the manuscript.
      </p>

      <h3 className="text-xl font-semibold mb-2">Disclosure and Conflicts of Interest</h3>
      <p className="mb-6 text-lg leading-relaxed">
        All authors should disclose in their manuscript any financial or other substantive conflict of interest that might be construed to influence the results or interpretation of their manuscript. All sources of financial support for the project should be disclosed.
      </p>

      <h3 className="text-xl font-semibold mb-2">Fundamental Errors in Published Works</h3>
      <p className="mb-6 text-lg leading-relaxed">
        When an author discovers a significant error or inaccuracy in his/her own published work, it is the author’s obligation to promptly notify the journal editor or publisher and cooperate with the editor to retract or correct the paper.
      </p>
    </div>
  );
};

export default PublicationEthicStatement;
