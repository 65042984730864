import React from 'react';
import { FaCalendar, FaCog } from 'react-icons/fa';

function CurrentIssue() {
  return (
    <div className="px-4 py-8 mx-auto max-w-5xl">
      <h1 className="text-[22px] sm:text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
        <FaCalendar className="text-blue-500 mr-2" />
        Indexing Services
      </h1>
      <div className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
        <div className="flex items-center mb-4">
          <FaCog className="text-green-400 text-3xl mr-4" />
        </div>
        <p className="text-lg text-gray-300">
        Indexing of journal is in process . . .
        </p>
      </div>
    </div>
  );
}

export default CurrentIssue;
