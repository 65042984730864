import React from 'react';
import { FaCalendar, FaArchive } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function CurrentIssue() {
  return (
    // <div>
    // <Helmet>
    //   <meta charset="utf-8" />
    //   <title>IJNGTSR - Current Issue</title>
    //   <meta name="description" content="We are publishing free of cost. IJNGTSR. Home · Track Article New · Aim and scope. Editorial Committee. Latest Announcement ... IJNGTSR All Rights Reserved. International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
    //   <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
    // </Helmet>
    // <div className="px-4 py-8 mx-auto max-w-5xl">
    //   <h1 className="text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
    //     <FaCalendar className="text-blue-500 mr-2" />
    //     Current Issue
    //   </h1>
    //   <div className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
    //     <div className="flex items-center mb-4">
    //       <FaCog className="text-yellow-400 text-3xl mr-4" />
    //       <h2 className="text-2xl font-semibold text-gray-200">Volume 1 Issue 1, September 2024</h2>
    //     </div>
    //     <p className="text-lg text-gray-300">
    //       is currently in processing. Stay tuned for updates on the release.
    //     </p>
    //   </div>
    // </div>
    // </div>
    <>
<div>
 <Helmet>
      <meta charset="utf-8" />
      <title>IJNGTSR - Current Issue</title>
   <meta name="description" content="We are publishing free of cost. IJNGTSR. Home · Track Article New · Aim and scope. Editorial Committee. Latest Announcement ... IJNGTSR All Rights Reserved. International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
    <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
    </Helmet>
</div>
<div className="px-4 py-8 mx-auto max-w-6xl">
<h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-100 flex items-center animate-pulse">
  <FaArchive className="text-blue-500 mr-2" />
  Current Issue
</h1>

<div className="bg-gray-700 p-4 sm:p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
  <h2 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4">
    <FaCalendar className="inline text-yellow-400 mr-2" />
    Year 2024 Issues
  </h2>

  <Link to='/volume_1' className='block'>
    <ul className="list-disc pl-5 text-blue-400 mb-6">
      <li className="border-b-2 border-yellow-400 pb-2 inline-block transition-transform duration-300 ease-in-out">
        Volume 1- Issue 1- Sep.&nbsp;2024
      </li>
    </ul>
  </Link>
</div>
</div>
</>
  );
}

export default CurrentIssue;
