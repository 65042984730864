import React from 'react';
import { FaMoneyCheckAlt } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

const PaymentPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="bg-gray-700 shadow-lg rounded-lg p-8 max-w-lg text-center transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <div className="flex justify-center items-center mb-4">
          <FaMoneyCheckAlt className="text-green-400 text-7xl animate-bounce" />
        </div>
        <h1 className="text-[18px] sm:text-3xl font-extrabold text-white mb-4 animate-pulse">Payment Information</h1>
        <p className="text-lg text-white font-medium mb-6">
          <strong>Currently, we are publishing free of cost !</strong>
        </p>
        <p className="text-md text-white mb-8">
          No need to send any payment at the moment. Enjoy our free publication services !
        </p>
        <Link
          to="/online-paper-submission"
          className="inline-block bg-blue-600 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:bg-blue-800 transition-colors duration-300 ease-in-out"
        >
          Submit Paper
        </Link>
      </div>
    </div>
  );
};

export default PaymentPage;
