import React from 'react';
import { FaLockOpen, FaUsers, FaBalanceScale } from 'react-icons/fa';

function OpenAccess() {
  return (
    <div>
    <h1 className="text-[22px] sm:text-3xl font-bold text-white mt-4 animate-pulse">Open Access</h1>
    <div className="max-w-4xl mx-auto p-6 bg-gray-700 rounded-lg mt-4">
      
      <div className="flex items-center space-x-2 mb-4">
        <FaLockOpen className="text-green-500 text-2xl" />
        <p className="text-lg text-gray-300 mb-4 ">
          Open access is an ongoing publication practice which differs in the way traditional methods of publishing papers to the public get submitted, reviewed, authenticated, and finally published. In the Open Access publication model, neither readers nor a reader’s institution are charged for access to articles or other resources. Users are free to read, download, copy, distribute, print, search, or link to the full texts of these articles for any non-commercial purpose without requiring a subscription to the journal in which these articles are published.
        </p>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <FaUsers className="text-blue-500 text-2xl" />
        <p className="text-lg text-gray-300 mb-4 ">
          IJNGTSR follows the Open Access model. The publication cost should be covered by the author’s institution or research funds. These Open Access charges replace subscription charges and allow the IJNGTSR to make the valuable published materials freely accessible to all interested online visitors, especially the researchers and young scholars from developing countries.
        </p>
      </div>
      <h2 className="text-xl font-semibold text-gray-100 mb-4 animate-bounce">Ethical guidelines for journal publication</h2>
      <div className="flex items-center space-x-2 mb-4">
        <FaBalanceScale className="text-yellow-500 text-2xl" />
        <p className="text-lg text-gray-300 mb-4 ">
          The publication of an article in the peer-reviewed journals published by IJNGTSR is a process of permanent knowledge improvement. It is a direct reflection of the quality of the work of the authors and the institutions that support them. Peer-reviewed articles support and embody the scientific method. It is therefore important to agree upon standards of expected ethical behaviour for all parties involved in the act of publishing: the author, the journal editor, the peer reviewer, the publisher, and the society of society-owned or sponsored journals.
        </p>
      </div>
      <p className="text-lg text-gray-300 mb-4 ">
        IJNGTSR takes its duties of guardianship over all stages of publishing extremely seriously and we recognize our ethical and other responsibilities.
      </p>
    </div>
    </div>
  );
}

export default OpenAccess;
