import React, { useState } from 'react';
import { FaSearch, FaIdBadge } from 'react-icons/fa';
import ProgressBar from './ProgressBar';

const statuses = ['Submit', 'Accept', 'Review', 'Publish'];

function Track() {
  const [articleId, setArticleId] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const API_KEY = '154122054';

  const handleChange = (e) => {
    setArticleId(e.target.value);
  };

  const validateArticleId = (id) => {
    return /^\d{8}$/.test(id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateArticleId(articleId)) {
      setStatusMessage('Article ID must be an 8-digit number.');
      setMessageType('error');
      return;
    }

    setShowSpinner(true);

    setTimeout(async () => {
      setShowSpinner(false);
      setIsLoading(true);
      setStatusMessage('Loading...');
      setMessageType('');

      try {
        const response = await fetch('https://qcthvmao2f.execute-api.ap-south-1.amazonaws.com/getstatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify({ articleNumber: articleId }),
        });

        const data = await response.json();

        if (response.ok) {
          if (data.length > 0) {
            if (data[0] === 'Reject') {
              setIsRejected(true);
              setShowProgressBar(true);
              setStatusMessage('Your Article Status: Rejected');
              setMessageType('error');
            } else {
              const statusIndex = statuses.indexOf(data[0]);
              if (statusIndex !== -1) {
                setCurrentStep(statusIndex);
                setShowProgressBar(true);
                setIsRejected(false);

                setTimeout(() => {
                  setShowProgressBar(false);
                }, 8000);
              }
              setStatusMessage(`Your Article Status: ${data[0]}`);
              setMessageType('success');
            }
          } else {
            setStatusMessage('This article number does not match any records.');
            setMessageType('error');
          }
        } else {
          setStatusMessage('This article number does not match any records.');
          setMessageType('error');
        }
      } catch (error) {
        setStatusMessage('This article number does not match any records.');
        setMessageType('error');
      } finally {
        setIsLoading(false);
        setArticleId('');
        setTimeout(() => {
          setStatusMessage('');
          setMessageType('');
        }, 6000);
      }
    }, 3000);
  };

  return (
    <div className="bg-gray-800 text-white max-w-4xl mx-auto px-2 py-8">
      <header className="text-center mb-8">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 animate-pulse">
          <FaSearch className="inline-block text-blue-400" size={36} /> Track Your Article
        </h1>
      </header>

      <section className="bg-gray-800 p-8 rounded-lg mb-8">
        <div className="mb-6">
          <p className="text-lg mb-4">
            IJNGTSR now allows you to track your article status. This functionality helps you view the latest status of your manuscript without needing to contact us.
          </p>
          <p className="text-lg mb-4">
            Enter your article ID, which you received in the manuscript acceptance email.
          </p>
          {/* <p className="text-lg mb-6 text-gray-300">
            <FaInfoCircle className="inline-block mr-2" /> Note: "Article Id" is provided to paid articles only.
          </p> */}
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <label htmlFor="article-id" className="text-xl font-semibold mb-2 flex items-center animate-bounce">
            <FaIdBadge className="text-blue-400 mr-2" size={24} /> Article ID:
          </label>
          <div className="flex flex-col items-center space-y-4 mt-5">
            <input
              type="text"
              id="article-id"
              value={articleId}
              onChange={handleChange}
              placeholder="Enter Your Article ID"
              className="bg-gray-700 text-white border border-gray-400 rounded-lg p-2 w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2"
              required
              maxLength={8}
              pattern="\d{8}"
            />
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 relative"
            >
              {showSpinner ? (
                <div className="spinner absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-4 border-t-4 border-blue-500 border-solid rounded-full w-6 h-6 animate-spin"></div>
              ) : (
                isLoading ? (
                  <div className="spinner absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-4 border-t-4 border-blue-500 border-solid rounded-full w-6 h-6 animate-spin"></div>
                ) : (
                  'Track'
                )
              )}
            </button>
          </div>

          <div className="mt-4 text-center">
            {statusMessage && (
              <p className={`mt-4 ${messageType === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                {statusMessage}
              </p>
            )}
          </div>
        </form>
        {showProgressBar && !isRejected && <ProgressBar currentStep={currentStep} />}
        {isRejected && (
          <div className="w-full px-8 py-4 mt-4">
            <div className="relative grid w-full h-24 m-0 overflow-hidden text-white shadow-lg place-items-center rounded-xl bg-gradient-to-tr from-red-900 to-red-800 bg-clip-border shadow-gray-900/20">
              <div className="w-full px-20 pt-4 pb-8">
                <div className="relative flex items-center justify-between w-full">
                  <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-white/50"></div>
                  <div
                    className="absolute left-0 top-2/4 h-0.5 bg-red-500 transition-all duration-500"
                    style={{ width: '100%' }}
                  ></div>
                  {['Reject'].map((step, index) => (
                    <div
                      key={index}
                      className="relative z-10 grid h-4 w-4 place-items-center rounded-full font-bold text-white transition-all duration-300 bg-red-500"
                    >
                      <div className="absolute -bottom-[2.3rem] w-max text-center text-xs">
                        <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                          {step}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-4 text-center text-red-500">Your article has been rejected.</div>
          </div>
        )}
      </section>
    </div>
  );
}

export default Track;
