import React, { useState } from 'react';
import { FaUserShield, FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Admin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const API_KEY = '154122054'; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    setTimeout(async () => {
      try {
        const response = await axios.post('https://s6gcy64ur3.execute-api.ap-south-1.amazonaws.com/login', {
          action: 'login',
          email,
          password
        },
        {
          headers: {
            'x-api-key': API_KEY
          }});
        if (response.data.token) {
          localStorage.setItem('authToken', response.data.token);
          navigate('/dashb');
        } else {
          setError('Invalid credentials');
          setTimeout(() => {
            setError('');
          }, 3000);
        }
      } catch (error) {
        setError('Error logging. Please try again.');
        setTimeout(() => {
          setError('');
        }, 3000);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-800">
      <div className="bg-gray-700 p-8 rounded-lg shadow-lg max-w-md w-full transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <div className="flex justify-center mb-0">
          <FaUserShield className="text-5xl text-indigo-400 animate-bounce" />
        </div>
        <h2 className="text-3xl font-bold text-center text-gray-100 mb-6 animate-pulse">Admin Login</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-indigo-100 text-sm font-bold mb-2" htmlFor="email">
              Email:
            </label>
            <div className="flex items-center border border-gray-600 rounded-md bg-gray-800 focus-within:ring-2 focus-within:ring-indigo-600 transition duration-300">
              <FaEnvelope className="text-indigo-400 ml-2" />
              <input
                id="email"
                type="email"
                className="w-full py-2 px-3 rounded-md bg-gray-800 text-gray-100 border border-none focus:outline-none focus:ring-0 transition-all duration-0 ease-in-out"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
                autoFocus
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-indigo-100 text-sm font-bold mb-2" htmlFor="password">
              Password:
            </label>
            <div className="flex items-center border border-gray-600 rounded-md bg-gray-800 focus-within:ring-2 focus-within:ring-indigo-600 transition duration-300 relative">
              <FaLock className="text-indigo-400 ml-2" />
              <input
                id="password"
                type={showPassword ? 'text' : 'password'} 
                className="w-full py-2 px-3 rounded-md bg-gray-800 text-gray-100 focus:outline-none"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                className="absolute right-3 text-indigo-400 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-300"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Admin;
